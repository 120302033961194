<i18n>
{
  "it": {
      "title": "Sensori collegati al nodo analogico",
      "subTitle": "Ogni grafico corrisponde ad uno dei sensori collegato al nodo analogico. La frequenza di campionamento, di ogni sensore collegato al nodo analogico, è relativa alla modalità di acquisizione impostata."
  },
  "en": {
      "title": "Analog communication node",
      "subTitle": "Each graph corresponds to one of the sensors connected to the analog node. The sample rate of each sensor connected to the analog node is related to the set acquisition mode."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "anNodeBallon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 400px;
}
</style>

