<i18n>
{
  "en": {
      "title": "Transmission Trend",
      "subTitle": "Graph representing the trend of the daily averages of the transmissions sent by the selected sensors, in the specific time interval set."

  },
  "it": {
      "title": "Andamento delle Trasmissioni",
      "subTitle": "Grafico rappresentante l’andamento delle medie giornaliere delle trasmissioni inviate dai sensori selezionati, nello specifico intervallo di tempo impostato."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "txTrendBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 360px;
}
</style>
