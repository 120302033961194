<template>
  <GoogleMapLoader :mapConfig="mapConfig" apiKey="AIzaSyD-AmtAwshjNylnrIqfl_Ja9P9lSz_4Ufk">
    <!--     <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
    </template>-->
  </GoogleMapLoader>
</template>

<script>
/* import GoogleMapMarker from "./GoogleMapMarker";
 */ import GoogleMapLoader from "./GoogleMapLoader.vue";
import { mapSettings } from "@/constants/mapSettings";

export default {
  components: {
    GoogleMapLoader /* ,
    GoogleMapMarker */
  },
  props: {
    coordinates: {
      lat: Number,
      lng: Number
    }
  },
  data() {
    return {
      markers: [
        {
          id: "a",
          position: { lat: 43.771967, lng: 11.242986 }
        }
      ]
    };
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.coordinates
      };
    },
    mapCenter() {
      return { lat: 43.771967, lng: 11.242986 };
    }
  }
};
</script>

