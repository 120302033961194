<i18n>
{
  "en": {
      "title": "Temperature graph",
      "subTitle": "Trend of the temperature detected in the last 24 hours by the selected sensor."

  },
  "it": {
      "title": "Grafico della temperatura",
      "subTitle": "Andamento della temperatura rilevata nelle ultime 24 ore  dal sensore selezionato."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "temperatureBallon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 360px;
}
</style>
