<i18n>
{
  "en": {
      "title": "Information on the last 24 hours",
      "subTitle": "In this section you can view the latest updated data, collected during the last monitoring day.",
      "li1": "View the summary of some important parameters",
      "li2": "View the graph of the trend in the last 24 hours of the fundamental parameter detected by the single sensor"

  },
  "it": {
      "title": "Informazioni sulle ultime 24 ore",
      "subTitle": "In questa sezione puoi visualizzare gli ultimi dati aggiornati, rilevati durante l’ultima giornata di monitoraggio.",
      "li1": "Visualizza il riepilogo di alcuni importanti parametri ",
      "li2": "Visualizza il grafico dell’andamento nelle ultime 24 ore del parametro fondamentale rilevato dal singolo sensore."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
      </ul>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "StructBallon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean
  }
};
</script>
<style scoped>
#root{
    width: 430px;
}
li{
  margin: 6px 0;
}
</style>
