<template>
  <!-- Bridge img -->
  <div class="bridge-parent">
    <SensorToggle v-if="sensors" id="sensortoggle" :typesFilter="typesFilter" :sensors="sensors" :selectedSensor="selectedFromParent" @type-selected="this.typeSelected" />

    <!-- Tips in overlay -->
    <div id="help-tip" class="over-info">
      <h2>{{ $t("message.deviceselect") }}</h2>
      <img class="info-svg" @click="this.showInfo" src="../../../public/assets/icons/info.svg" alt />

      <!-- Modal Info Selezione Deck -->
      <!-- <ModalInfo v-show="isInfoVisible" class="modal-info" id="info-deck">
        <template v-slot:header>
          <h2>{{ $t("message.deviceselect") }}</h2>
        </template>
        <template v-slot:body class="modal-temp">
          <p>{{ $t("message.deviceselectinfo") }}</p>
        </template>
      </ModalInfo> -->
      <p>{{ $t("message.bridgescroll") }}</p>
    </div>

    <!-- Elemento Bridge -->
    <div class="bridge-child">
      <div id="bridge-image"></div>
      <!-- Sensor Overlay -->
      <div class="sensors-container">
        <!-- Bottone del sensore -->
        <div
          class="tooltip"
          v-for="sensor in validSensors"
          v-bind:key="sensor.eui"
          width="64px"
          height="64px"
          :style="{
            marginTop: computeMarginTop(sensor) + 'px',
            marginLeft: computeMarginLeft(sensor) + 'px',
          }"
        >
          <button class="button" v-bind:class="[isSelected(sensor) ? 'selected-button' : '']" @click="selectSensor(sensor)">
            <span class="sensor-dot" :style="{ backgroundColor: getSensorColor(sensor) }"></span>
            {{ sensor.eui.substr(sensor.eui.length - 5) }}
          </button>
          <span class="tooltiptext">
            <p>EUI: {{ sensor.eui.substr(sensor.eui.length - 5) }}</p>
            <p>Name: {{ sensor.userConfig.name }}</p>
            <p>
              Last Seen:
              {{ sensor.stats ? (sensor.stats.lastTX ? new Date(sensor.stats.lastTX).toLocaleString() : " - ") : new Date(sensor.lastTX) }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* import ModalInfo from "../ui/ModalInfo.vue"; */
import SensorToggle from "../ui/SensorToggle.vue";
import sensorsUtils from "../../helpers/sensors";
import api from "../../services/api";
import $ from "jquery";
/*
BridgeDraw ha il compito di plottare l'immagine del ponte, con i relativi sensori sopra.
Emette un evento 'sensor-selected' quando viene selezionato un sensore.
*/
export default {
  name: "bridge",
  components: {
   /*  ModalInfo, */
    SensorToggle,
  },
  data() {
    return {
      selectedSensors: [this.sensors[0]],
      isInfoVisible: false,
      actualType: "deck",
      tempSensors: [],
      refreshingInterval: null,
    };
  },
  props: {
    selectedStructure: Object,
    spans: Number,
    sensors: Array,
    selectedFromParent: Object,
    typesFilter: Array,
    multiSensorSelect: Boolean,
  },
  methods: {
    computeMarginTop: function (sensor) {
      let marginTop;
      marginTop = sensor.bridgeSide * 155 - 255;
      return marginTop;
    },
    computeMarginLeft: function (sensor) {
      return (sensor.bridgeSpan / 10) * 343 - 290;
    },
    isSelected: function (sensor) {
      return this.selectedSensors && this.selectedSensors.find((s) => s.eui == sensor.eui);
    },
    isTiltMeter: function (sensor) {
      return sensor.type === "tiltmeter";
    },
    isDeck: function (sensor) {
      return sensor.type === "deck";
    },
    isCrackMeter: function (sensor) {
      return sensor.type === "crackmeter";
    },
    isAccelerometer: function (sensor) {
      return sensor.type === "accelerometer";
    },
    showInfo: function () {
      this.isInfoVisible = !this.isInfoVisible;
      this.isTempInfoVisible = false;
    },
    selectSensor: function (sensor) {
      if (this.multiSensorSelect) {
        const alreadySelected = this.selectedSensors.find((s) => s.eui === sensor.eui);
        if (alreadySelected) {
          this.selectedSensors = this.selectedSensors.filter((s) => s.eui != sensor.eui);
        } else {
          this.selectedSensors.push(sensor);
        }
        this.$emit("sensors-selected", this.selectedSensors);
      } else {
        this.selectedSensors = [sensor];
        this.$emit("sensor-selected", this.selectedSensors[0]);
      }
    },
    appendBridgeSection(nSpanes) {
      //img.width = "1280";
      let bridgeWrapper = document.getElementById("bridge-image");
      for (let i = 0; i < nSpanes; i++) {
        var img = document.createElement("img");
        img.classList.add("bridge-part");
        img.src = require("../../../public/assets/BridgeChop.png");
        bridgeWrapper.prepend(img);
      }
    },
    typeSelected(type) {
      // Se viene selezionato un tipo ed è diverso da quello del sensore attualmente selezionato, seleziono un nuovo sensore
      this.actualType = type;
      if (!this.selectedFromParent || type != this.selectedFromParent.type) this.selectSensor(this.validSensors[0]);
    },
    checkIfOnline: sensorsUtils.checkIfOnline,
    getSensorColor(sensor) {
      let color;
      if (this.checkIfOnline(sensor)) {
        color = "green";
      } else {
        color = "red";
      }
      return color;
    },
    // Refresha gli oggetti sensori
    async refreshSensorsStats() {
      const sensorsFromApi = await api.getSensorsSettings(this.selectedStructure._id);
      this.tempSensors = JSON.parse(JSON.stringify(sensorsFromApi));
      console.log("Sensors bridge refresh");
    },
  },
  computed: {
    validSensors: function () {
      let type = this.actualType;
      return this.tempSensors.filter(function (el) {
        return el.type === type;
      });
    },
  },

  watch: {
    sensors: {
      // Quando cambiano i sensori della struttura Seleziono un nuovo sensore e disegno il ponte nuovo
      handler: async function (newValue, oldValue) {

        if (!newValue[0] || !oldValue[0] || (newValue[0].eui != oldValue[0].eui)) {
          this.tempSensors = JSON.parse(JSON.stringify(newValue || []));
          if (this.multiSensorSelect) {
            this.selectedSensors = this.validSensors;
            this.$emit("sensors-selected", this.selectedSensors);
          } else {
            this.selectSensor(this.tempSensors[0]);
          }
          let bridgeWrapper = document.getElementById("bridge-image");
          bridgeWrapper.textContent = "";
          this.appendBridgeSection(this.spans);
        }
      },
      deep: true,
    },
   /*  multiSensorSelect: {
      handler: async function (newValue) {
        if (newValue) {
          this.selectedSensors = this.validSensors;
          this.$emit("sensors-selected", this.selectedSensors);
          console.log("ehiiii");
        } else {
          this.selectSensor(this.tempSensors[0]);
        }
      },
    }, */
  },
  mounted() {
    this.appendBridgeSection(this.spans),
      // Quando clicco sull'immagine aggiungo classe "active"
      $(".bridge-child").mousedown(function () {
        var hovered = $(".tooltip").find(".button:hover").length;

        if (hovered < 1) {
          $(".bridge-child").addClass("active");
        }
      });
    // Quando smetto di cliccare la rimuovo
    $(".bridge-child").mouseup(function () {
      $(".bridge-child").removeClass("active");
    });
    // Quando sposto il mouse la rimuovo
    $(".bridge-child").mouseleave(function () {
      $(".bridge-child").removeClass("active");
    });
    // Quando effettuo un win resize riporto il ponte in posizione iniziale
    $(window).resize(function () {
      $(".bridge-child").css("left", "0px");
    });
    // Con doppio click riporto il ponte in posizione iniziale
    $(".bridge-child").dblclick(function () {
      $(".bridge-child").css("left", "0px");
    });
    // Drag handler
    $(".bridge-child").draggable({
      cursor: "move",
      containment: "bridge-parent",
      axis: "x",
      stop: function () {
        const rightMargin = 550;
        const leftMargin = 160;
        let bridgeWidth = $("#bridge-image").width();
        let windowWidth = $(window).width() - rightMargin;
        const leftOffset = ($("#bridge-image").offset().left);
        const rightOffset = ($("#bridge-image").offset().left) + bridgeWidth;
        if (leftOffset > windowWidth || rightOffset < leftMargin) {
          $(".bridge-child").css("left", "0px");
        }
      },
    });
    this.tempSensors = JSON.parse(JSON.stringify(this.sensors));
    if (this.multiSensorSelect) {
      this.selectedSensors = this.validSensors;
      this.$emit("sensors-selected", this.selectedSensors);
    }/*  else {
      this.selectSensor(this.tempSensors[0]);
    } */
    this.refreshingInterval = setInterval(this.refreshSensorsStats, 60000);
  },
  beforeDestroy: function () {
    clearInterval(this.refreshingInterval);
  },
};
</script>
<style scoped>
.total-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.modal-info {
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(91, 96, 118);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#info-deck {
  position: absolute;
  top: -120px;
  left: 460px;
  max-width: 500px;
}

#info-temp {
  position: absolute;
  top: 60px;
  width: 500px;
  left: 80px;
}
.bridge-parent {
  padding-top: 155px;
}
.bridge-child {
  display: grid;
  justify-content: center;
  transition: transform 0.3s;
  transform: scale(1);
  z-index: 9;
}
.bridge-img {
  top: 0;
  left: 0;
  cursor: pointer;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 1px 7px 4px);
}

#bridge-image {
  display: flex;
  width: max-content;
}
.bridge-child.active > .bridge-img {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.bridge-child.active {
  transform: scale(1.02);
}

#help-tip {
  position: absolute;
  width: 430px;
  top: 52px;
  left: 120px;
  text-align: left;
  justify-content: left;
  display: grid;
}

#temperature {
  position: absolute;
  display: grid;
  width: 205px;
  top: 220px;
  left: 60%;
  text-align: left;
}

@media only screen and (min-width: 1300px) {
  #temperature {
    position: absolute;

    width: 205px;
    top: 220px;
    left: 6 0vw;
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  #temperature {
    position: absolute;

    width: 205px;
    top: 300px;
    left: 120px;
    text-align: left;
  }
}

#sensortoggle {
  position: absolute;
  top: 128px;
  left: 117px;
  width: fit-content;
}

@media all and (min-width: 300px) {
  #help-tip {
    left: 30px;
  }

  #sensortoggle {
    position: absolute;
    top: 128px;
    left: 30px;
  }
}

.over-info > h2 {
  grid-row: 1;
  grid-column: 1;
  font-size: 1em;
  color: white;
  margin: 0;
}
.over-info > p {
  grid-column: 1;
  grid-row: 2;
  margin: 0;
}
.over-info > img {
  justify-self: end;
  grid-column: 1;
  grid-row: 1;
}

.sensors-container {
  width: 640px;
  top: 0;
  left: 0;
  position: relative;
}

.tooltip {
  font-size: smaller;
  position: absolute;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
  transition-delay: 0s;
  width: max-content;
  background-color: rgb(31, 33, 44);
  /* border: solid 2px white; */
  color: #fff;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 55%;
  margin-left: -7vw;
  font-family: inherit;
}

.tooltiptext p {
  margin: 0;
}

.tooltip:hover > .tooltiptext {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: rgb(31, 33, 44) transparent transparent transparent;
}
.button {
  width: 120px;
  height: 36px;
  /* padding: 5px; */
  padding-right: 15px;
  display: flex;
  /* padding: 3px; */
  /* justify-content: space-evenly; */
  align-items: center;
  font-size: 1.2em;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 5px white;
  border-radius: 20px;
  transition: transform 0.1s linear;
}

.selected-button {
  background-color: rgb(35, 145, 254);
}

.button:hover {
  /*   background-color: rgb(47, 112, 177); */
  transition: transform 0.1s linear;
  -ms-transform: scale(1.126) translate3d(0, 0, 0);
  -webkit-transform: scale(1.126) translate3d(0, 0, 0);
  transform: scale(1.126) translate3d(0, 0, 0);
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}

.sensor-dot {
  position: relative;
  /* left: 12px; */
  height: 12px;
  width: 12px;
  /* padding: 7px; */
  margin: 4px;
  background-color: unset;
  border-radius: 12px;
  display: inline-block;
  border: solid 2px white;
  z-index: 12;
  /*   display:block;
  text-align:center; 
  margin:0 auto; */
}
/*  .selected-button::after {
    display:inline-block;
    content:'';
    width:2px;
    height:2px;
    vertical-align:middle;
    background-color: white;
    border-radius: 50%;
}  */
/* .button:after {
    display:inline-flex;
    content:'';
    width:14px;
    height:14px;
    border: solid 2px white;
    vertical-align:middle;
    border-radius: 50%;
} */
</style>
