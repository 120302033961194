// Ritorna 
function paginateArray(arr, size) {
    return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / size);
        let page = acc[idx] || (acc[idx] = []);
        page.push(val);

        return acc;
    }, []);
}

module.exports = {
    paginateArray
}