<template>
  <div id="root" class="generic-wrapper" ref="hello">
    <div id="overlay" v-if="false"></div>
    <header>
      <h2>{{$t('message.statusMonitor')}}</h2>
      <img src="../../../public/assets/icons/info.svg" alt />
    </header>
    <main>
      <SelectTypeToggle id="select-type" :sensors="sensors" v-model="selectedType" v-if="false" />
      <!-- Tabella Sensori -->
      <div class="info-tab">
        <table style="width: 100%">
          <!-- <thead> -->
          <tr class="tr-head" @click="capture">
            <th>{{$t('message.sensor')}}</th>
            <th>Attivaz.</th>
            <th>{{$t('message.sensor')}}</th>
            <th>Eventi Tot.</th>
            <th>Ultima Trasm.</th>
            <th>RSSI</th>
          </tr>
          <!-- </thead> -->
          <!-- <tbody> -->
          <tr v-for="sensor in filteredSensors" :key="sensor.eui" :class="[selectedSensor && selectedSensor.eui === sensor.eui ? 'selected' : '', 'tr-content']">
            <td>{{ sensor.eui.substr(sensor.eui.length - 5) }}</td>
            <td>{{ sensor.userConfig.activationThreshold || "-" }}</td>
            <td>{{ sensor.userConfig.threshold || "-" }}</td>
            <td>{{ "-" }}</td>
            <td>{{ sensor.stats.lastTX ? new Date(sensor.stats.lastTX).toLocaleString() : " - " }}</td>
            <td>{{ "-" }}</td>
          </tr>
          <!-- </tbody> -->
        </table>
      </div>
    </main>
    <footer>
      <PageSelect v-model="page" :pagesNumber="pagesNumber" />
    </footer>
  </div>
</template>
<script>
import SelectTypeToggle from "./SelectTypeToggle.vue";
import PageSelect from "../ui/PageSelect.vue";
import pagination from "../../helpers/pagination";
import { screenshot } from "../../helpers/download";

export default {
  name: "system-state",
  props: {
    sensors: Array,
    selectedSensor: Object,
    selectedTypeFromParent: String, // TODO togliere
  },
  components: {
    SelectTypeToggle,
    PageSelect,
  },
  data() {
    return {
      selectedType: "deck",
      page: 1,
      itemsPerPage: 5,
      output: "",
    };
  },
  computed: {
    sensorsByType() {
      const sensorsByType = this.sensors.filter((sn) => sn.type === this.selectedType);
      return sensorsByType;
    },
    filteredSensors() {
      const paginatedArray = pagination.paginateArray(this.sensorsByType, this.itemsPerPage);
      return paginatedArray[this.page - 1];
    },
    pagesNumber() {
      return Math.ceil(this.sensorsByType.length / this.itemsPerPage);
    },
  },
  methods: {
    selectType(newType) {
      this.selectedType = newType;
    },
    capture() {
      const el = this.$refs.hello;
      screenshot(el);
    },
  },
  watch: {
    selectedType: function () {
      this.page = 1;
    },
    selectedTypeFromParent: function (newType) {
      this.page = 1;
      this.selectedType = newType;
    },
  },
};
</script>
<style scoped>
#root {
  /* justify-self: left; */
  /*   margin-left: 4%;
  width: 62%; */
  width: 100%;
  display: grid;
  grid-template-rows: 50px 10fr 50px;
  grid-template-columns: 10fr;
  position: relative;
}
header {
  border-bottom: solid 2px white;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  z-index: 2;
}
main {
  min-height: 340px;
}
img {
  cursor: pointer;
}
h2 {
  font-weight: 400;
  font-size: 1.2em;
  padding-right: 40px;
}

/* overlay */
#overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: black;
  opacity: 0.7;
  border-radius: 10px;
}

#select-type {
  max-width: 600px;
  margin: 30px;
}
.info-tab {
  margin: 20px;
  overflow: scroll;
}
table {
  border-collapse: collapse;
  color: white;
}
th {
  font-weight: 400;
}
tr {
  border-bottom: solid 2px rgb(80, 84, 105);
  height: 30px;
}
.tr-head > th {
  color: rgb(198, 198, 198);
}
.tr-content {
  font-size: 0.9em;
}
.selected {
  background-color: rgb(31, 34, 46);
}
</style>