<i18n>
{
  "en": {
      "title": "Data compensation",
      "subTitle": "Through this function, you can view the angular data detected by the inclinometer with or without the compensation relating to the installation mode.",
      "li1": "Data compensation activated: It displays only the angular variation between the angle currently detected with respect to the initial installation one.",
      "li2": "Data compensation disabled: Displays the absolute angle detected with respect to the gravitational axis. The data is less reliable because it is affected by the sensor installation offset."

  },
  "it": {
      "title": "Compensazione angolare",
      "subTitle": "Tramite questa funzione puoi visualizzare i dati angolari rilevati dall’inclinometro con o senza la  compensazione relativa alla modalità di installazione.",
      "li1": "Compensazione dati attivata: Visualizza la variazione angolare tra l’angolo attualmente rilevato rispetto a quello iniziale di installazione.",
      "li2": "Compensazione dati disattivata: Visualizza l’angolo assoluto rispetto all’asse gravitazionale. Il dato è meno affidabile perché risente dell’offset d’installazione del sensore."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="right" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
      </ul>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../BallonTemplate.vue";

export default {
  name: "DashBallon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 440px;
}
li{
  margin: 6px 0;
}
</style>

