<template>
  <div class="modal-mask" v-if="sensor && event">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header>
          <h2>Dettagli evento</h2>
          <!-- <img src="../../../public/assets/icons/info.svg" alt /> -->
        </header>

        <main>
          <div v-if="sensor.type === 'deck'">
            <LinePlot
              :height="this.plotHeight"
              :traces="this.getDispTrace"
              yTitle="Displacement (mm)"
              xTitle="Time (s)"
              :xRange="[this.getDisplacementBegin(this.getDispTrace), 20]"
            />
          </div>
          <div v-if="sensor.type === 'accelerometer'"></div>
          <div v-if="sensor.type === 'tiltmeter'"></div>
          <div v-if="sensor.type === 'analog-node'"></div>
        </main>

        <footer>
          <button type="button" class="std-button" @click="closeMe">Chiudi</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import LinePlot from "../graphs/LinePlot.vue";

export default {
  name: "ModalEvent",
  components: {
    LinePlot,
  },
  props: {
    event: Object,
    sensor: Object,
  },
  data() {
    return {
      plotHeight: 490,
      baseTrace: {
        connectgaps: true,
        /* Trace di base a cui vengono aggiunti attributi in base al grafico */
        line: {
          color: "rgb(17, 205, 239)",
          width: 4,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 4,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    getDisplacementBegin: function (trace) {
      let begin = trace[0].x ? trace[0].x.length / this.getDeckSampleRate(trace[0].x.length) - 20 : 0;
      return -begin;
    },
  },
  computed: {
    getDispTrace: function () {
      // Ritorna la trace relativa all Deck
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.sensor.type === "deck") {
        if (this.event.payload) {
          let sampleRate = this.getDeckSampleRate(this.event.payload.dati.length);
          let begin = -(this.event.payload.dati ? this.event.payload.dati.length / sampleRate - 20 : 0);
          basicTrace.y = this.event.payload.dati;
          basicTrace.x = this.event.payload.dati.map((val, ind) => ind / sampleRate + begin);
        }
      }
      return [basicTrace];
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  width: 40vw;
  height: 44vh;
  min-width: 680px;
  min-height: 400px;
  /* top: 22vh;
  left: 0; */
  position: sticky;
  grid-template-rows: 50px 10fr 50px;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
header {
  border-bottom: solid 2px white;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
footer {
  border-top: solid 2px white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  z-index: 2;
}
h2 {
  font-weight: 400;
  font-size: 1.2em;
  padding-right: 40px;
}
</style>