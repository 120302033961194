<template>
  <div class="player">
    <video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions" :playsinline="true" @ended="onPlayerEnded($event)"></video-player>
  </div>
</template>
<script>
export default {
  name: "VideoModalTutorial",
  props: {
    video: String,
  },
  data() {
    return {
      // videojs options
      playerOptions: {
        /* height: '360', */
        width: "460",
        autoplay: true,
        muted: true,
        loop: true,
        fluid: false,
        /* language: 'en', */
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            // mp4
            src: require("../../../public/assets/video/ANGOLI-DEFINITIVI_720.mp4"),
            // webm
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          },
        ],
        poster: require("../../../public/assets/video/ANGOLI-DEFINITIVI_POSTER.jpg"),
      },
    };
  },
  mounted() {
    // console.log('this is current player instance object', this.player)
    /* setTimeout(() => {
      console.log('dynamic change options', this.player)
      // change src
      // this.playerOptions.sources[0].src = 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm';
      // change item
      // this.$set(this.playerOptions.sources, 0, {
      //   type: "video/mp4",
      //   src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      // })
      // change array
      // this.playerOptions.sources = [{
      //   type: "video/mp4",
      //   src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      // }]
      this.player.muted(false)
    }, 5000) */
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    closeMe() {
      this.$emit("close-me");
    },
    // listen event
    /*     onPlayerPlay(player) {
      console.log('player play!', player)
    },
    onPlayerPause(player) {
      console.log('player pause!', player)
    }, */
    onPlayerEnded(player) {
      console.log("player ended!", player);
      this.closeMe();
    } /* ,
    onPlayerLoadeddata(player) {
      console.log('player Loadeddata!', player)
    },
    onPlayerWaiting(player) {
      console.log('player Waiting!', player)
    },
    onPlayerPlaying(player) {
      console.log('player Playing!', player)
    },
    onPlayerTimeupdate(player) {
      console.log('player Timeupdate!', player.currentTime())
    },
    onPlayerCanplay(player) {
      console.log('player Canplay!', player)
    },
    onPlayerCanplaythrough(player) {
      console.log('player Canplaythrough!', player)
    },
    playerStateChanged(playerCurrentState) {
      console.log('player current update state', playerCurrentState)
    }, */,
    // player is ready
    /* playerReadied(player) {
      // seek to 10s
      console.log('example player 1 readied', player)
      player.currentTime(10)
    }, */
  },
};
</script>
<style scoped>
* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
}
input[disabled] {
  pointer-events: none;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
}
body {
  background-color: white !important;
}
h1 {
  font-size: 1.3em;
}
p {
  font-size: 0.8em;
  color: rgb(198, 198, 198);
  padding: 0px;
}
.outer-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background-color: transparent;
}
#modal {
  position: relative;
  /*   width: fit-content;
  height: fit-content; */
  /* min-height: 400px; */
  border-radius: 10px;
  overflow: hidden;
  display: block;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
#header {
  position: absolute;
  background-color: transparent;
  height: 40px;
  width: 100%;
  /*   height: 100%;
  min-height: 45px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1; */
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  /* border-bottom: solid 2px white; */
}

.close {
  align-self: center;
  cursor: pointer;
  top: 15px;
  right: 35px;
  width: 3%;
}
.close::after,
.close::before {
  background-color: black;
}
</style>
