<template>
  <div class="overview-info noselect">
    <!-- LOADING OVERLAY -->
    <div class="infoi" v-if="!getLoadingStatus && getThSensor">
      <h2>{{ $t("message.loading") }}</h2>
    </div>
    <div class="infoi" v-else-if="!getThSensor"><h2>No selected sensor...</h2></div>

    <!-- Inner Wrapper -->
    <div class="main-wrap over-info" v-if="this.getThSensor">
      <!-- Info -->
      <div class="help-icon-container">
        <h2 class="first-block">{{ $t("message.deviceInfo") }}: {{ getThSensor.eui.substr(getThSensor.eui.length - 5) || " - " }}</h2>
        <div class="help-icon-container">
          <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisibleDevice = true" @mouseleave="isInfoVisibleDevice = false" />
          <DeviceBalloon :visible="isInfoVisibleDevice" style="position: absolute; margin-top: 20px; margin-left: 24px" @close="isInfoVisibleDevice = false" />
        </div>
      </div>
      <p class="first-block">{{ $t("message.last24Data") }} - {{ today }}</p>
      <!-- Info Help -->
      <!-- Switch Compensazione -->
      <div v-if="compensable" class="third-block head">
        <div style="display: flex; justify-content: space-between">
          <h2 :class="{ textdisabled: !compensable }">{{ $t("message.dataCompensation") }}</h2>
          <div class="help-icon-container">
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisibleCompensation = true" @mouseleave="isInfoVisibleCompensation = false" />
            <CompensBalloon :visible="isInfoVisibleCompensation" style="position: absolute; margin-top: 20px; right: 24px" @close="isInfoVisibleCompensation = false" />
          </div>
        </div>
      </div>
      <div v-if="compensable" class="third-block second-row" id="relative-switch">
        <p :class="{ textdisabled: !compensable }">{{ this.getRelativeMode ? $t("message.activeF") : $t("message.inactiveF") }}</p>
        <label class="switch"
          ><input type="checkbox" :disabled="!compensable" v-model="relativeMode" />
          <span class="slider round" :class="{ colordisabled: !compensable }"></span>
        </label>
      </div>

      <div class="horiz-divider"></div>

      <!-- Ultima Temperatura -->
      <h1 class="first-block third-row">{{ getTemperatures && getTemperatures.length > 0 ? getTemperatures[0].temperature.toFixed(2) : " - " }}°C</h1>
      <p class="first-block fourth-row">{{ $t("message.lastTemperature") }}</p>
      <div class="vert-divider second-block"></div>

      <!-- Counter degli eventi -->
      <h1 v-if="getSensorType === 'deck'" class="second-block third-row">{{ getVibrations.length || " - " }}</h1>
      <h1 v-if="getSensorType === 'accelerometer'" class="second-block third-row">{{ getAccelerations.length || " - " }}</h1>
      <h1 v-if="getSensorType === 'crackmeter'" class="second-block third-row">{{ getCracks.length || " - " }}</h1>
      <h1 v-if="getSensorType === 'tiltmeter'" class="second-block third-row">{{ getTilts.length || " - " }}</h1>
      <h1 v-if="getSensorType === 'vibrometer'" class="second-block third-row">{{ getPPVs ? getPPVs.underThreshold.length + getPPVs.overThreshold.length : null || " - " }}</h1>
      <p v-if="getSensorType === 'deck'" class="second-block fourth-row">{{ $t("message.thresholdCrossingCount") }}</p>
      <p v-else class="second-block fourth-row">{{ $t("message.samples") }}</p>
      <div class="vert-divider third-block"></div>

      <!-- VALORE MASSIMO -->
      <!-- Deck -->
      <h1 v-if="getSensorType === 'deck'" class="third-block third-row">{{ (getMaxVibration || " - ") + " " + getMesaurementUnit }}</h1>
      <!-- Accelerometer -->
      <h1 v-if="getSensorType === 'accelerometer'" class="third-block third-row">{{ (getMaxAccelerations || " - ") + " " + getMesaurementUnit }}</h1>
      <!-- Crackmeter -->
      <h1 v-if="getSensorType === 'crackmeter' && !getThSensor.subType" class="third-block third-row">{{ (getMaxCrack || " - ") + " " + getMesaurementUnit }}</h1>
      <!-- Vibrometer -->
      <h1 v-if="getSensorType === 'vibrometer' && !getThSensor.subType" class="third-block third-row">{{ (getMaxPPV || " - ") + " " + getMesaurementUnit }}</h1>
      <!-- Loadcell -->
      <h1 v-if="getSensorType === 'crackmeter' && getThSensor.subType === 'loadcell'" class="third-block third-row">{{ (getMaxLoad || " - ") + " " + getMesaurementUnit }}</h1>
      <!-- Tiltmeter -->
      <h1 v-if="getSensorType === 'tiltmeter'" class="third-block third-row">{{ (getMaxTilt || " - ") + getMesaurementUnit }}</h1>
      <p v-if="getSensorType === 'deck'" class="third-block fourth-row">{{ $t("message.maxPeak") }}</p>
      <p v-else-if="getSensorType === 'accelerometer'" class="third-block fourth-row">{{ $t("message.maxAcceleration") }}</p>
      <p v-else-if="getSensorType === 'crackmeter'" class="third-block fourth-row">{{ $t("message.maxCrack") }}</p>
      <p v-else-if="getSensorType === 'vibrometer'" class="third-block fourth-row">Max Amplitude</p>
      <p v-else class="third-block fourth-row">{{ $t("message.maxTilt") }}</p>
    </div>
  </div>
</template>
<script>
/* Modulo che mostra le informazioni di base relative al sensore selezionato. */

import { mapGetters, mapActions } from "vuex";
import dates from "../../../helpers/dates.js";
import CompensBalloon from "../../tutorial/balloons/CompensationBl.vue";
import DeviceBalloon from "../../tutorial/balloons/overview/Overview24hBl.vue";

import math from "../../../helpers/math.js";

// prettier-ignore
export default {
    name: "overview-info",
    components: {
        CompensBalloon,
        DeviceBalloon
    },
    data() {
        return {
            today: dates.ddmmyyyyFormat(new Date()),
            isInfoVisibleCompensation: false,
            isInfoVisibleDevice: false,
            userThreshold:null
        };
    },
    computed: {
        ...mapGetters({
            getThSensor: "lastevents/getSelectedSensor",
            getTemperatures: "lastevents/getTemperatures",
            getLoadingStatus: "lastevents/getLoadingStatus",
            getAccelerations: "lastevents/getAccelerations",
            getVibrations: "lastevents/getVibrations",
            getTilts: "lastevents/getTilts",
            getPPVs: "lastevents/getPPVs",
            getCracks: "lastevents/getCracks",
            getBaseValue: "lastevents/getBaseValue",
            getRelativeMode: "lastevents/getRelativeMode",
            getSensorType:"lastevents/getSensorType",
            getInterfaceSettings: "user/getInterface"
        }),
        getUserUnits(){
          return this.getInterfaceSettings ? this.getInterfaceSettings.dimensions : null;
        },
        getVelocityUnit(){
          return (this.getUserUnits || {}).velocity || 'mm/s'
        },
        relativeMode: {
            get () {
                return this.getRelativeMode
            },
            set (value) {
                this.setRelativeMode(value)
            }
        },
        threshold: {
            get(){
                return this.getThSensor.threshold
            }, 
            set(value){
                this.userThreshold = value
            }
        },
        /* Restituisce il massimo modulo tra le accelerazioni sui 3 assi nelle ultime 24h */
        getMaxAccelerations: function() {
            let maxX;
            let maxY;
            let maxZ;
            if (this.getAccelerations && this.getAccelerations.length > 0){
                maxX = Math.max(...this.getAccelerations.map(a => (a.acceleration.xPeak*1000).toFixed(3)));
                maxY = Math.max(...this.getAccelerations.map(a => (a.acceleration.yPeak*1000).toFixed(3)));
                maxZ = Math.max(...this.getAccelerations.map(a => (a.acceleration.zPeak*1000).toFixed(3)));
            }
            let maxTotal = Math.max(maxX, maxY, maxZ);
            return maxTotal ? math.toFixedIfNecessary(maxTotal, 6) : null; 
        },
        getMaxCrack: function(){
            let maxCrack;
            let offset = this.getRelativeMode && this.getBaseValue ? this.getBaseValue : 0;
            if (this.getCracks && this.getCracks.length > 0){
                maxCrack = (Math.max.apply(Math, this.getCracks.map(a => (a.sample - offset)))).toFixed(5);
            }
            return maxCrack;
        },
        getMaxPPV: function(){
          if (!this.getPPVs){
            return null;
          }
          const maxPPVOver = this.getPPVs.overThreshold ? Math.max(...this.getPPVs.overThreshold.map(ppv => Math.max(...ppv.amplitudes))) : null;
          const maxPPVUnder = this.getPPVs.underThreshold ? Math.max(...this.getPPVs.underThreshold.map(ppv => Math.max(...ppv.amplitudes))) : null;
          const maxPPV = Math.max(maxPPVOver, maxPPVUnder);
          return isFinite(maxPPV) ? maxPPV : null;
        },
        getMaxLoad: function(){
            let maxCrack;
            if (this.getCracks && this.getCracks.length > 0){
                maxCrack = (Math.max.apply(Math, this.getCracks.map(a => (((a.sample / (3.92 * Math.pow(10, -3))) - 6400) * (11.71875))))).toFixed(5);
            }
            return maxCrack;
        },
        getMaxTilt: function(){
            let maxPhi;
            let maxTheta;
            if (this.getTilts && this.getTilts.length > 0){
                maxPhi = Math.max(...this.getTilts.map(a => Math.abs(a.phiAngle))).toFixed(6);
                maxTheta = Math.max(...this.getTilts.map(a => Math.abs(a.thetaAngle))).toFixed(6);
            }
            let degreesMax = Math.max(maxPhi, maxTheta);
            let mmMax = math.degreesToMM(degreesMax);
            return (this.getUserUnits && this.getUserUnits.degrees === 'mm/m') ? (mmMax ? mmMax.toFixed(6) : null) : (degreesMax);
        },
        getMaxVibration: function(){
            if (this.getVibrations && this.getVibrations.length > 0){
            return (Math.max.apply(Math, this.getVibrations.map(a => a.payload.piccoMax - a.payload.piccoMin))).toFixed(2);
            }
            else return null;
        },
        compensable: function(){
            return (this.getSensorType === 'tiltmeter' || (this.getSensorType === 'crackmeter' && this.getThSensor.subType != 'loadcell'))
        },
        getMesaurementUnit: function(){
            let unit;
            if (this.getSensorType === 'deck' || this.getSensorType === 'crackmeter'){
              if (this.getThSensor.subType === 'loadcell')
                unit = 'N'
              else 
                unit = "mm"
            } else if (this.getSensorType === 'accelerometer'){
                unit = "mg"
            } else if (this.getSensorType === 'tiltmeter'){
                unit = (this.getUserUnits && this.getUserUnits.degrees === 'mm/m') ? " mm/m" : "°"
            } else if (this.getSensorType === 'vibrometer'){
                unit = this.getVelocityUnit
            }
            return unit;
        }
    },
    methods: {
        ...mapActions({setRelativeMode: "lastevents/setRelativeMode"}),
  }
};
</script>
<style scoped>
h2 {
  align-self: center;
}

/* Root Wrapper */
.overview-info {
  position: relative;
  min-width: 1100px;
  width: 92%;
  background-color: rgb(45, 48, 65);
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: solid 3px white;
  min-height: 100px;
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}

/* Head */
.head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.head > img {
  margin-left: 10px;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .overview-info {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
/* Informazioni in overlay */
.over-info > h2 {
  font-size: 0.9em;
  color: white;
  margin: 0;
  margin-bottom: 5px;
}
.over-info > h1 {
  font-size: 1em;
  font-weight: 400;
  color: white;
  margin: 0;
}
.over-info > p {
  font-size: 0.8em;
  margin: 0;
  color: rgb(198, 198, 198);
}
.over-info {
  text-align: left;
  position: relative;
  display: grid;
}

/* Grid Rows */
.first-row {
  grid-row: 4;
}

.second-row {
  grid-row: 2;
}

.third-row {
  grid-row: 7;
}

.fourth-row {
  grid-row: 8;
}

/* Grid Block */
h2.third-block {
  text-align: right;
  font-weight: 500;
}

.head.third-block {
  position: relative;
  align-items: end;
  text-align: right;
  grid-row: 1;
}

/* Relative Mode Switch */
#relative-switch {
  display: flex;
  align-items: center;
  justify-self: end;
}
#relative-switch > p {
  margin: 0;
  padding-right: 10px;
  font-weight: 500;
}

.help-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.help-icon-container > img {
  margin: auto 0 auto 6px;
}

/* Divisori */
.horiz-divider {
  height: 2px;
  background-color: rgba(80, 84, 105, 1);
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-column-start: 1;
  grid-column-end: 4;
}
.vert-divider {
  grid-row-start: 7;
  grid-row-end: 9;
  width: 2px;
  background-color: rgba(80, 84, 105, 1);
  position: relative;
  right: 10%;
}

/* Inner Wrapper */
.main-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  margin-left: 60px;
  margin-right: 60px;
  grid-template-columns: 34% 32% 34%;
}
</style>
