<i18n>
{
  "en": {
    "rootHeading": "PCPV / Frequency scatterplot",
    "rootDesc": "For the selected time interval, the data detected by the sensor and the selected alarm threshold are shown on a speed/frequency graph.",
    "rootDesc24Hrs": "For the selected time interval, the data detected by the sensor and the selected alarm threshold are shown on a speed/frequency graph.",
    "ppvCustomDesc": "Time trend of the velocity vector modulus constructed using the axes specified in the sensor settings.",
    "PCPVHeading": "PCPV - Peak velocity of a point component",
    "PCPVDesc": "Trend over time of the absolute value of each component of the speed.",
    "peakFreqHeading": "Spectrum peak frequency",
    "peakFreqDesc": "Trend over time of the peak frequency for each component.",
    "regulation": "Guideline",
    "monitoringType": "Evaluation type",
    "buildingCategory": "Building category",
    "vibrationsType": "Type of measurement",
    "monitoredAxes": "Monitoring axes",
    "customThreshold": "Custom threshold",
    "ppvMode": "PPV mode",
    "amplitude": "Amplitude",
    "frequency": "Frequency",
    "date": "Date",
    "selectedDevice": "Selected device",
    "vibrometerScatterAggregationMessage": "The data that are below the alarm threshold are aggregated at intervals of {range}, choosing for each interval the data with the highest value. Any data exceeding the alarm threshold are always highlighted. ",
    "vibrometerPCPVAggregationMessage": "The data that are below the alarm threshold are aggregated at intervals of {range}, choosing for each interval the data with the highest value. Any data exceeding the alarm threshold are always highlighted.",
    "vibrometerFreqPeakAggregationMessage": "The data is aggregated in a manner consistent with the chart above.",
    "overThreshold": "Over Threshold",
    "underThreshold": "Under Threshold",
    "axes": "axes"
  },
  "it": {
    "rootHeading": "Scatterplot PCPV / Frequenza",
    "rootDesc": "Per l’intervallo di tempo selezionato sono riportati su un grafico velocità/frequenza i dati rilevati dal sensore e la soglia di allarme selezionata.",
    "ppvCustomDesc": "Andamento temporale del modulo del vettore velocità costruito utilizzando gli assi specificati nelle impostazioni del sensore.",
    "rootDesc24Hrs": "Per l’intervallo di tempo selezionato sono riportati su un grafico velocità/frequenza i dati rilevati dal sensore e la soglia di allarme selezionata.",
    "PCPVHeading": "PCPV - Velocità di picco di una componente puntuale",
    "PCPVDesc": "Andamento nel tempo del valore assoluto di ciascuna componente della velocità.",
    "peakFreqHeading": "Frequenza del picco dello spettro",
    "peakFreqDesc": "Andamento nel tempo della frequenza del picco per ciascuna componente.",
    "regulation": "Normativa",
    "monitoringType": "Tipo di valutazione",
    "buildingCategory": "Categoria edificio",
    "vibrationsType": "Tipo di misura",
    "monitoredAxes": "Assi di monitoraggio",
    "customThreshold": "Soglia custom",
    "ppvMode": "Modalità PPV",
    "amplitude": "Ampiezza",
    "frequency": "Frequenza",
    "date": "Data",
    "selectedDevice": "Device selezionato",
    "vibrometerScatterAggregationMessage": "I dati che stanno sotto la soglia di allarme sono aggregati ad intervalli di {range} scegliendo per ogni intervallo il dato che presenta il valore massimo. Eventuali dati che superano la soglia di allarme sono invece sempre messi in evidenza. ",
    "vibrometerPCPVAggregationMessage": "I dati che stanno sotto la soglia di allarme sono aggregati ad intervalli di {range} scegliendo per ogni intervallo il dato che presenta il valore massimo. Eventuali dati che superano la soglia di allarme sono invece sempre messi in evidenza.",
    "vibrometerFreqPeakAggregationMessage": "I dati sono aggregati in maniera coerente al grafico sopra.",
    "overThreshold": "Sopra Soglia",
    "underThreshold": "Sotto Soglia",
    "axes": "assi"
  }
}
</i18n>

<template>
  <div id="temps" class="details-wrapper noselect" v-if="sensorObj">
    <!-- Loading Overlay -->
    <transition name="load">
      <SmallLoadingLogo v-if="loading" />
    </transition>
    <!-- Wrapper Info in Overlay -->
    <div class="infoi" v-show="!loading && PPVs && PPVs.underThreshold.length === 0 && PPVs.overThreshold.length === 0">
      <h2>{{ $t("message.noDataForSensor") }}...</h2>
    </div>
    <div class="add-info">
      <div class="inner-info">
        <p>
          {{ $t("selectedDevice") }}:<span class="value">{{ sensorObj.eui || "-" }}</span>
        </p>
      </div>
      <div class="inner-info">
        <p>
          {{ $t("regulation") }}:<span class="value">{{ getThresholdObject.normativeName || "-" }}</span>
        </p>
      </div>
      <div class="inner-info" v-if="getThresholdObject.normativeName !== 'custom'">
        <p>
          {{ $t("monitoringType") }}:<span class="value">{{ getThresholdObject.monitoringTypeName || "-" }}</span>
        </p>
      </div>
      <div class="inner-info" v-if="getThresholdObject.normativeName !== 'custom' && getThresholdObject.buildingCategoryName">
        <p>
          {{ $t("buildingCategory") }}:<span class="value">{{ getThresholdObject.buildingCategoryName || "-" }}</span>
        </p>
      </div>
      <div class="inner-info" v-if="getThresholdObject.normativeName !== 'custom' && getThresholdObject.vibrationsTypeName">
        <p>
          {{ $t("vibrationsType") }}:<span class="value">{{ getThresholdObject.vibrationsTypeName || "-" }}</span>
        </p>
      </div>
      <div class="inner-info" v-if="getThresholdObject.normativeName === 'custom'">
        <p>
          {{ $t("customThreshold") }}:<span class="value">{{ getActualCustomThreshold(getThresholdObject.customThreshold) || "-" }}</span>
        </p>
      </div>
      <div class="inner-info" v-if="getThresholdObject.normativeName === 'custom'">
        <p>
          {{ $t("ppvMode") }}:<span class="value">{{ getThresholdObject.customOperation === "sqrt" || "-" }}</span>
        </p>
      </div>
      <div class="inner-info">
        <p>
          {{ $t("monitoredAxes") }}:<span class="value">{{ (axesNames || []).toString() || "-" }}</span>
        </p>
      </div>
    </div>
    <div class="plot-wrapper">
      <!-- PPV Custom -->
      <div class="main-wrap over-info" v-if="sensorObj && customNormWithPPV">
        <div style="display: flex;">
          <h2>{{ "PPV " + $t("axes") + " " + axesNames.toString().toUpperCase() }}</h2>
        </div>
        <p class="first-block">{{ $t("ppvCustomDesc") }}</p>
        <!-- Su quale range temporale prendo il massimo -->
        <p v-if="PPVs && PPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerPCPVAggregationMessage", { range: PPVs.groupValue }) }}</p>
      </div>
      <div class="plot-wrap" v-if="customNormWithPPV">
        <LinePlot
          :height="300"
          :traces="getPPVMonitorValueTraces"
          :threshold="getActualCustomThreshold(getThresholdObject.customThreshold)"
          :xTitle="$t('date')"
          :yTitle="'PPV (' + axesNames.toString().toUpperCase() + `) [${this.getVelocityUnit}]`"  
        />
      </div>
      <div class="main-wrap over-info" v-if="sensorObj">
        <div style="display: flex;">
          <h2>{{ $t("rootHeading") }}</h2>
        </div>
        <p class="first-block">{{ last24Hrs ? $t("rootDesc24Hrs") : $t("rootDesc") }}</p>
        <!-- Su quale range temporale prendo il massimo -->
        <p v-if="PPVs && PPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerPCPVAggregationMessage", { range: PPVs.groupValue }) }}</p>
      </div>
      <!-- Scatter -->
      <div class="single-plot">
        <LinePlot :height="plotHeight" :traces="[...getPPVScatterTraces, ...getScatterNormativeTraces]" :xTitle="$t('frequency') + ' [Hz]'" :yTitle="$t('amplitude') + ` [${this.getVelocityUnit}]`" :showLegend="true" />
      </div>
      <!-- Resto dei grafici -->
      <div v-if="!minimalView">
        <div class="main-wrap over-info">
          <div style="display: flex;">
            <h2>{{ $t("PCPVHeading") }}</h2>
            <div>
              <img class="pointer" src="../../../public/assets/icons/help.svg" alt="drag" @mouseover="isPcpvInfoVisible = true" @mouseleave="isPcpvInfoVisible = false" />
              <PCPVBalloon :visible="isPcpvInfoVisible" style="position: absolute; margin-left: 20px" @close="isPcpvInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("PCPVDesc") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="PPVs && PPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerPCPVAggregationMessage", { range: PPVs.groupValue }) }}</p>
        </div>
        <div class="multi-plot">
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVAmplitudesTraces[0]" :xTitle="$t('date')" :yTitle="$t('amplitude') + ` X [${getVelocityUnit}]`" />
            <div class="dark-plot" v-if="getPPVAmplitudesTraces[0].length === 0"></div>
          </div>
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVAmplitudesTraces[1]" :xTitle="$t('date')" :yTitle="$t('amplitude') + ` Y [${getVelocityUnit}]`" />
            <div class="dark-plot" v-if="getPPVAmplitudesTraces[1].length === 0"></div>
          </div>
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVAmplitudesTraces[2]" :xTitle="$t('date')" :yTitle="$t('amplitude') + ` Z [${getVelocityUnit}]`" />
            <div class="dark-plot" v-if="getPPVAmplitudesTraces[2].length === 0"></div>
          </div>
        </div>
        <div class="main-wrap over-info">
          <div style="display: flex;">
            <h2>{{ $t("peakFreqHeading") }}</h2>
            <div>
              <img class="pointer" src="../../../public/assets/icons/help.svg" alt="drag" @mouseover="isFreqPeakInfoVisible = true" @mouseleave="isFreqPeakInfoVisible = false" />
              <FreqPeakBalloon :visible="isFreqPeakInfoVisible" style="position: absolute; margin-left: 20px" @close="isFreqPeakInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("peakFreqDesc") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="PPVs && PPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerFreqPeakAggregationMessage", { range: PPVs.groupValue }) }}</p>
        </div>
        <div class="multi-plot">
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVFftFreqsTraces[0]" :xTitle="$t('date')" :yTitle="$t('frequency') + ' X [Hz]'" />
            <div class="dark-plot" v-if="getPPVFftFreqsTraces[0].length === 0"></div>
          </div>
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVFftFreqsTraces[1]" :xTitle="$t('date')" :yTitle="$t('frequency') + ' Y [Hz]'" />
            <div class="dark-plot" v-if="getPPVFftFreqsTraces[1].length === 0"></div>
          </div>
          <div class="plot-wrap">
            <LinePlot :height="300" :traces="getPPVFftFreqsTraces[2]" :xTitle="$t('date')" :yTitle="$t('frequency') + ' Z [Hz]'" />
            <div class="dark-plot" v-if="getPPVFftFreqsTraces[2].length === 0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LinePlot from "../graphs/LinePlot.vue";
//import PeakToolBalloon from "../tutorial/balloons/tools/PPVPeakToolBl.vue";
import PCPVBalloon from "../tutorial/balloons/tools/PPVPcpvToolBl.vue";
import FreqPeakBalloon from "../tutorial/balloons/tools/PPVFreqPeakToolBl.vue";
import SmallLoadingLogo from "../ui/SmallLoadingLogo.vue";
import { getManifests } from "../../services/api";
import {mmToInch} from "../../helpers/math"

import {mapGetters} from "vuex"
export default {
  name: "ppv-normative-charts",
  components: {
    LinePlot,
    //PeakToolBalloon,
    PCPVBalloon,
    FreqPeakBalloon,
    SmallLoadingLogo,
  },
  props: {
    sensorObj: Object,
    PPVs: Object,
    minimalView: Boolean,
    loading: Boolean,
    last24Hrs: Boolean,
  },
  data() {
    return {
      normatives: null, // Lista di normative prese dal manifest
      // Dati Normative
      regulation: null,
      monitoringType: null,
      buildingCategory: null,
      vibrationsType: null,
      axes: null,
      customThreshold: null,
      isMainInfoVisible: false,
      isPcpvInfoVisible: false,
      isFreqPeakInfoVisible: false,
      plotHeight: 600,
      baseTrace: {
        connectgaps: true,
        /* Trace di base a cui vengono aggiunti attributi in base al grafico */
        line: {
          color: "rgb(17, 205, 239)",
          width: 2,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 4,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
      /* Trace di base a cui vengono aggiunti attributi in base al grafico */
      baseLineTrace: {
        connectgaps: true,
        line: {
          dash: "dash",
          color: "orange",
          width: 2,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
      baseMarkersTrace: {
        mode: "markers",
        name: "Markers",
        marker: {
          color: "rgb(255, 0, 0)",
          size: 6,
        },
        type: "scatter",
        hovertemplate: "<b>%{data.name}</b><br>" + "%{xaxis.title.text}: %{x}<br>" + "%{yaxis.title.text}: %{y}<br><br>" + "<b>%{text}</b><br><br>" + "<extra></extra>",
      },
    };
  },
  computed: {
    ...mapGetters({
      userInterface: 'user/getInterface',
    }),
    getVelocityUnit(){
      return this.userInterface && this.userInterface.dimensions && this.userInterface.dimensions.velocity
        ? this.userInterface.dimensions.velocity
        : 'mm/s';
    },
    /* 

    Restituisce un oggetto contente le PPVs per tipo.
    L'API restituisce due array differenti per gli elementi SOPRA SOGLIA e quelli SOTTO SOGLIA. 
    Si considerando SOPRA SOGLIA gli eventi che:
    1) Rispondono ad una normativa non custom ed ha gli assi indicati sopra la soglia (e di conseguenza il campo alarmedAxes con gli assi)
    2) Rispondono ad una normativa custom e:
      A) Hanno SQRT mode OFF ed almeno un asse tra quelli impostati sopra la soglia (e di conseguenza il campo alarmedAxes con gli assi)
      B) Hanno SQRT mode ON ed il valore monitorValue sopra la soglia (e di conseguenza il campo alarmedMonitorValue a true)
    NB: per "asse sopra la soglia" si intende il valore dell'amplitude sopra la soglia.
    
    */
    getAxisPPVs: function() {
      // Lista di elementi OVER (sia per i singoli assi che per il monitor value)
      let over = {
        dates: [],
        labels: [],
        monitorValues: [],
        // X
        overAmpsX: [],
        overFreqsX: [],
        // Y
        overAmpsY: [],
        overFreqsY: [],
        // Z
        overAmpsZ: [],
        overFreqsZ: [],
      };
      // Lista di elementi UNDER, ovvero che non hanno nessun asse ed il monitor value sopra la soglia
      let under = {
        dates: [],
        labels: [],
        monitorValues: [],
        // X
        ampsX: [],
        freqsX: [],
        // Y
        ampsY: [],
        freqsY: [],
        // Z
        ampsZ: [],
        freqsZ: [],
      };

      this.PPVs &&
        this.PPVs.underThreshold.forEach((a) => {
          const timestamp = new Date(a.timestamp);
          let retString = timestamp.toLocaleString();
          under.dates.push(timestamp);
          under.ampsX.push(a.amplitudes[0]);
          under.ampsY.push(a.amplitudes[1]);
          under.ampsZ.push(a.amplitudes[2]);
          under.freqsX.push(a.fftFreqs[0]);
          under.freqsY.push(a.fftFreqs[1]);
          under.freqsZ.push(a.fftFreqs[2]);
          if (a.monitorValue !== null && a.monitorValue !== undefined) {
            retString += "<br><br>" + "PPV (" + this.axesNames.toString().toUpperCase() + "): " + a.monitorValue + ` ${this.getVelocityUnit}`;
            under.monitorValues.push(a.monitorValue);
          } else {
            under.monitorValues.push(null);
          }
          under.labels.push(retString);
        });

      this.PPVs &&
        this.PPVs.overThreshold.forEach((a) => {
          const timestamp = new Date(a.timestamp);
          over.dates.push(timestamp);
          let retString = timestamp.toLocaleString();
          if (a.alarmedAxes) {
            // Alert asse X
            if (a.alarmedAxes.includes("x")) {
              retString += "<br>" + this.$t("overThreshold").toUpperCase() + " X: " + a.amplitudes[0] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[0] + " Hz";
              over.overAmpsX.push(a.amplitudes[0]);
              over.overFreqsX.push(a.fftFreqs[0]);
            } else {
              if (this.axesNames && this.axesNames.includes("x")) {
                retString += "<br>" + this.$t("underThreshold").toUpperCase() + " X: " + a.amplitudes[0] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[0] + " Hz";
              }
              over.overAmpsX.push(null);
              over.overFreqsX.push(null);
            }
            // Alert asse Y
            if (a.alarmedAxes.includes("y")) {
              retString += "<br>" + this.$t("overThreshold").toUpperCase() + " Y: " + a.amplitudes[1] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[1] + " Hz";
              over.overAmpsY.push(a.amplitudes[1]);
              over.overFreqsY.push(a.fftFreqs[1]);
            } else {
              if (this.axesNames && this.axesNames.includes("y")) {
                retString += "<br>" + this.$t("underThreshold").toUpperCase() + " Y: " + a.amplitudes[1] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[1] + " Hz";
              }
              over.overAmpsY.push(null);
              over.overFreqsY.push(null);
            }
            // Alert asse Z
            if (a.alarmedAxes.includes("z")) {
              retString += "<br>" + this.$t("overThreshold").toUpperCase() + " Z: " + a.amplitudes[2] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[2] + " Hz";
              over.overAmpsZ.push(a.amplitudes[2]);
              over.overFreqsZ.push(a.fftFreqs[2]);
            } else {
              if (this.axesNames && this.axesNames.includes("z")) {
                retString += "<br>" + this.$t("underThreshold").toUpperCase() + " Z: " + a.amplitudes[2] + ` ${this.getVelocityUnit} @ ` + a.fftFreqs[2] + " Hz";
              }
              over.overAmpsZ.push(null);
              over.overFreqsZ.push(null);
            }
          }
          // Valore PPV custom SOPRA SOGLIA
          if (a.monitorValue !== null && a.monitorValue !== undefined) {
            retString += "<br><br>" + this.$t("overThreshold").toUpperCase() + " PPV (" + this.axesNames.toString().toUpperCase() + "): " + a.monitorValue + ` ${this.getVelocityUnit} @ `;
            over.monitorValues.push(a.monitorValue);
          } else {
            over.monitorValues.push(null);
          }
          over.labels.push(retString);
        });
      return { over: over, under: under };
    },
    // Traces dello scatterplot principale
    getPPVScatterTraces: function() {
      let returnTraces = [];
      let basicTraceX = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicTraceOutliersX = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicTraceOutliersY = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicTraceOutliersZ = JSON.parse(JSON.stringify(this.baseMarkersTrace));

      // Asse X UNDER THRESHOLD
      basicTraceX.x = this.getAxisPPVs.under.freqsX;
      basicTraceX.y = this.getAxisPPVs.under.ampsX;
      basicTraceX.name = "X" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceX.marker.color = "#1ba1e2";
      basicTraceX.marker.size = 6;
      basicTraceX.text = this.getAxisPPVs.under.labels;
      // Asse Y UNDER THRESHOLD
      basicTraceY.x = this.getAxisPPVs.under.freqsY;
      basicTraceY.y = this.getAxisPPVs.under.ampsY;
      basicTraceY.name = "Y" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceY.marker.color = "#008a00";
      basicTraceY.marker.size = 6;
      basicTraceY.text = this.getAxisPPVs.under.labels;
      // Asse Z UNDER THRESHOLD
      basicTraceZ.x = this.getAxisPPVs.under.freqsZ;
      basicTraceZ.y = this.getAxisPPVs.under.ampsZ;
      basicTraceZ.name = "Z" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceZ.marker.color = "#aa00ff";
      basicTraceZ.marker.size = 6;
      basicTraceZ.text = this.getAxisPPVs.under.labels;

      // Asse X OVER THRESHOLD
      basicTraceOutliersX.x = this.getAxisPPVs.over.overFreqsX;
      basicTraceOutliersX.y = this.getAxisPPVs.over.overAmpsX;
      basicTraceOutliersX.name = "X Over";
      basicTraceOutliersX.marker.color = "red";
      basicTraceOutliersX.marker.size = 6;
      basicTraceOutliersX.text = this.getAxisPPVs.over.labels;
      // Asse Y OVER THRESHOLD
      basicTraceOutliersY.x = this.getAxisPPVs.over.overFreqsY;
      basicTraceOutliersY.y = this.getAxisPPVs.over.overAmpsY;
      basicTraceOutliersY.name = "Y Over";
      basicTraceOutliersY.marker.color = "red";
      basicTraceOutliersY.marker.size = 6;
      basicTraceOutliersY.text = this.getAxisPPVs.over.labels;
      // Asse Z OVER THRESHOLD
      basicTraceOutliersZ.x = this.getAxisPPVs.over.overFreqsZ;
      basicTraceOutliersZ.y = this.getAxisPPVs.over.overAmpsZ;
      basicTraceOutliersZ.name = "Z Over";
      basicTraceOutliersZ.marker.color = "red";
      basicTraceOutliersZ.marker.size = 6;
      basicTraceOutliersZ.text = this.getAxisPPVs.over.labels;

      // Inietto le trace a seconda degli assi che voglio
      if (this.axesNames && this.axesNames.includes("x")) {
        returnTraces.push(basicTraceX);
        //returnTraces.push(basicSoftTraceX);
        returnTraces.push(basicTraceOutliersX);
      }
      if (this.axesNames && this.axesNames.includes("y")) {
        returnTraces.push(basicTraceY);
        //returnTraces.push(basicSoftTraceY);
        returnTraces.push(basicTraceOutliersY);
      }
      if (this.axesNames && this.axesNames.includes("z")) {
        returnTraces.push(basicTraceZ);
        //returnTraces.push(basicSoftTraceZ);
        returnTraces.push(basicTraceOutliersZ);
      }
      return returnTraces;
    },
    // Traces grafici delle frequenze FFT
    getPPVFftFreqsTraces: function() {
      let returnTraces = [[], [], []];
      let basicTraceX = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseTrace));
      let basicMarkerTraceX = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicMarkerTraceY = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicMarkerTraceZ = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      basicTraceX.x = this.getAxisPPVs.under.dates;
      basicTraceX.name = "X" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceX.y = this.getAxisPPVs.under.freqsX;
      basicTraceY.x = this.getAxisPPVs.under.dates;
      basicTraceY.name = "Y" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceY.y = this.getAxisPPVs.under.freqsY;
      basicTraceZ.x = this.getAxisPPVs.under.dates;
      basicTraceZ.name = "Z" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceZ.y = this.getAxisPPVs.under.freqsZ;

      basicMarkerTraceX.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceX.name = "Over X";
      basicMarkerTraceX.y = this.getAxisPPVs.over.overFreqsX;
      basicMarkerTraceX.marker.color = "#ff0000";
      basicMarkerTraceX.marker.size = 4;
      basicMarkerTraceX.text = this.getAxisPPVs.over.overAmpsX.map((am) => "Amp. " + am + ` ${this.getVelocityUnit}`);
      basicMarkerTraceY.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceY.name = "Over Y";
      basicMarkerTraceY.y = this.getAxisPPVs.over.overFreqsY;
      basicMarkerTraceY.marker.color = "#ff0000";
      basicMarkerTraceY.marker.size = 4;
      basicMarkerTraceY.text = this.getAxisPPVs.over.overAmpsY.map((am) => "Amp. " + am + ` ${this.getVelocityUnit}`);
      basicMarkerTraceZ.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceZ.name = "Over Z";
      basicMarkerTraceZ.y = this.getAxisPPVs.over.overFreqsZ;
      basicMarkerTraceZ.marker.color = "#ff0000";
      basicMarkerTraceZ.marker.size = 4;
      basicMarkerTraceZ.text = this.getAxisPPVs.over.overAmpsZ.map((am) => "Amp. " + am + ` ${this.getVelocityUnit}`);
      // Inietto le trace a seconda degli assi che voglio
      if (this.axesNames && this.axesNames.includes("x")) {
        returnTraces[0] = [basicTraceX, basicMarkerTraceX];
      }
      if (this.axesNames && this.axesNames.includes("y")) {
        returnTraces[1] = [basicTraceY, basicMarkerTraceY];
      }
      if (this.axesNames && this.axesNames.includes("z")) {
        returnTraces[2] = [basicTraceZ, basicMarkerTraceZ];
      }
      return returnTraces;
    },
    // Traces grafici delle amplitudes FFT
    getPPVAmplitudesTraces: function() {
      let returnTraces = [[], [], []];
      let basicTraceX = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseTrace));
      let basicMarkerTraceX = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicMarkerTraceY = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      let basicMarkerTraceZ = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      basicTraceX.x = this.getAxisPPVs.under.dates;
      basicTraceX.name = "X" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceX.y = this.getAxisPPVs.under.ampsX;
      basicTraceY.x = this.getAxisPPVs.under.dates;
      basicTraceY.name = "Y" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceY.y = this.getAxisPPVs.under.ampsY;
      basicTraceZ.x = this.getAxisPPVs.under.dates;
      basicTraceZ.name = "Z" + (this.PPVs && this.PPVs.groupValue ? " (" + this.PPVs.groupValue + " max)" : "");
      basicTraceZ.y = this.getAxisPPVs.under.ampsZ;

      basicMarkerTraceX.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceX.name = "Over X";
      basicMarkerTraceX.y = this.getAxisPPVs.over.overAmpsX;
      basicMarkerTraceX.marker.color = "#ff0000";
      basicMarkerTraceX.marker.size = 4;
      basicMarkerTraceX.text = this.getAxisPPVs.over.overFreqsX.map((fr) => "Freq. " + fr + " Hz");
      basicMarkerTraceY.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceY.name = "Over Y";
      basicMarkerTraceY.y = this.getAxisPPVs.over.overAmpsY;
      basicMarkerTraceY.marker.color = "#ff0000";
      basicMarkerTraceY.marker.size = 4;
      basicMarkerTraceY.text = this.getAxisPPVs.over.overFreqsY.map((fr) => "Freq. " + fr + " Hz");
      basicMarkerTraceZ.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceZ.name = "Over Z";
      basicMarkerTraceZ.y = this.getAxisPPVs.over.overAmpsZ;
      basicMarkerTraceZ.marker.color = "#ff0000";
      basicMarkerTraceZ.marker.size = 4;
      basicMarkerTraceZ.text = this.getAxisPPVs.over.overFreqsZ.map((fr) => "Freq. " + fr + " Hz");
      // Inietto le trace a seconda degli assi che voglio
      if (this.axesNames && this.axesNames.includes("x")) {
        returnTraces[0] = [basicTraceX, basicMarkerTraceX];
      }
      if (this.axesNames && this.axesNames.includes("y")) {
        returnTraces[1] = [basicTraceY, basicMarkerTraceY];
      }
      if (this.axesNames && this.axesNames.includes("z")) {
        returnTraces[2] = [basicTraceZ, basicMarkerTraceZ];
      }
      return returnTraces;
    },
    // Traces grafici del monitor value
    getPPVMonitorValueTraces: function() {
      let basicTraceUnder = JSON.parse(JSON.stringify(this.baseTrace));
      let basicMarkerTraceOver = JSON.parse(JSON.stringify(this.baseMarkersTrace));
      basicMarkerTraceOver.hovertemplate = "<b>%{data.name}</b><br>" + "%{xaxis.title.text}: %{x}<br>" + "%{yaxis.title.text}: %{y}<br><br>" + "<extra></extra>"; // Tolgio il 'text' dalla label che qui non mi serve

      // Under Threshold
      basicTraceUnder.x = this.getAxisPPVs.under.dates;
      basicTraceUnder.y = this.getAxisPPVs.under.monitorValues;
      basicTraceUnder.name = "PPV (" + this.axesNames.toString().toUpperCase() + ")";

      // Over Threshold
      basicMarkerTraceOver.x = this.getAxisPPVs.over.dates;
      basicMarkerTraceOver.name = "Over Monitor Value";
      basicMarkerTraceOver.y = this.getAxisPPVs.over.monitorValues;
      basicMarkerTraceOver.marker.color = "#ff0000";
      basicMarkerTraceOver.marker.size = 4;

      console.log("this.baseTrace", this.baseTrace);
      console.log('basicTraceUnder', basicTraceUnder);
      console.log('basicMarkerTraceOver', basicMarkerTraceOver);
      console.log('this.getAxisPPVs', this.getAxisPPVs);
      return [basicTraceUnder, basicMarkerTraceOver];
    },
    getThresholdObject() {
      return this.sensorObj && this.sensorObj.userConfig.threshold;
    },
    // Scorro la gerarchia di settaggi per trovare gli assi da plottare
    getNormative() {
      return this.getThresholdObject.normativeName !== "custom" && (this.normatives || []).find((nm) => nm.name === this.getThresholdObject.normativeName);
    },
    getMonitoringType() {
      return this.getNormative && this.getNormative.options.find((opt) => opt.monitoringName === this.getThresholdObject.monitoringTypeName);
    },
    getBuildingCategory() {
      return this.getMonitoringType && this.getMonitoringType.buildingsCategories.find((bc) => bc.categoryName === this.getThresholdObject.buildingCategoryName);
    },
    getVibrationsType() {
      return this.getBuildingCategory && this.getBuildingCategory.vibrationsType.find((vt) => vt.vibrationTypeName === this.getThresholdObject.vibrationsTypeName);
    },
    getAxes() {
      return this.getVibrationsType && this.getVibrationsType.axes;
    },
    axesNames() {
      let axisNames;
      if (this.getThresholdObject.normativeName !== "custom") {
        axisNames = this.getAxes && this.getAxes[0].axisName;
      } else {
        axisNames = this.getThresholdObject.customAxes;
      }
      return axisNames || [];
    },
    // Ritorna le traces relative alla soglia di allarme da plottare
    getScatterNormativeTraces: function() {
      let freqsX = [];
      let valuesX = [];
      // Non devo plottare la soglia
      if (this.customNormWithPPV) {
        return [];
      }
      if (this.getThresholdObject.normativeName === "custom") {
        // Plotto i punti della normativa custom
        freqsX = [0, 100];
        const threshold = this.getActualCustomThreshold(this.getThresholdObject.customThreshold)
        valuesX = [threshold, threshold];
      } else {
        // Prelevo i punti della norma da plottare dai manifest
        if (this.normatives) {
          console.log("noratives:", this.normatives);
          console.log("this.getThresholdObject.normativeName:", this.getThresholdObject.normativeName);
          const normative = this.normatives.find((nm) => nm.name === this.getThresholdObject.normativeName);
          console.log("normative: ",normative);
          // Prendo dal manifest i parametri completi della norma selezioanta
          const monitoringType = normative.options.find((opt) => opt.monitoringName === this.getThresholdObject.monitoringTypeName);
          const buildingCategory = monitoringType.buildingsCategories.find((bc) => bc.categoryName === this.getThresholdObject.buildingCategoryName);
          const vibrationsType = buildingCategory.vibrationsType.find((vt) => vt.vibrationTypeName === this.getThresholdObject.vibrationsTypeName);
          const alertPoints = vibrationsType.axes[0].alertPoints;
          freqsX = alertPoints.map((ap) => ap.freq);
          valuesX = alertPoints.map((ap) => {
            return this.getVelocityUnit === 'inch/s'
              ? mmToInch(ap.amplitude)
              : ap.amplitude
          });
        }
      }
      let basicTraceX = JSON.parse(JSON.stringify(this.baseLineTrace));
      basicTraceX.x = freqsX;
      basicTraceX.y = valuesX;
      basicTraceX.name = "Alarm threshold";
      return [basicTraceX];
    },
    customNormWithPPV() {
      return this.getThresholdObject.normativeName === "custom" && this.getThresholdObject.customOperation === "sqrt";
    },
  },
  methods:{
    getActualCustomThreshold(threshold){
      const isInch = this.getVelocityUnit === 'inch/s';
      return isInch ? mmToInch(threshold, 5) : threshold
    },
  },
  async mounted() {
    // Fetch delle normative per il sensore
    this.normatives = await getManifests({deviceType: "vibrometer", deviceRev: null, manifestType: "norm"});
    console.log("this.PPVs", this.PPVs)
  },
  watch: {
    sensorObj: async function(newValue) {
      // Fetch delle normative per il sensore
      if (newValue) {
        this.normatives = await getManifests({deviceType: "vibrometer", deviceRev: null, manifestType: "norm"});
      }
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 1em;
}

/* Inner Wrapper */
.details-wrapper {
  position: relative;
  margin-top: 30px;
  height: fit-content;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.main-wrap {
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}

.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 60px;
}
.over-info {
  grid-template-columns: 10fr min-content;
  text-align: left;
  position: relative;
}
.pointer {
  margin-left: 6px;
}

/* Contenitore grafico singolo */
.single-plot {
  width: 100%;
  grid-template-columns: 100%;
  display: grid;
}

/* Contenitore grafici multipli */
.multi-plot {
  width: 100%;
  grid-template-columns: 33% 34% 33%;
  display: grid;
}
.add-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 60px;
}
.value {
  font-size: 1.1em;
  margin-left: 6px;
  font-weight: 600;
  color: white;
}
.inner-info {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  text-align: left;
}
.inner-info p {
  margin: 0;
  color: rgb(198, 198, 198);
}
.plot-wrap {
  position: relative;
}
.dark-plot {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 10px;
  border-radius: 10px;
  opacity: 0.5;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
}
</style>
