<i18n>
{
  "en": {
    "structureTitle": "Dashboard",
    "structureSubTitle": "Select a sensor to view the data detected in the last 24h."

  },
  "it": {
    "structureTitle": "Dashboard",
    "structureSubTitle": "Seleziona un sensore di cui visualizzare i dati rilevati nelle ultime 24h."
  }
}
</i18n>
<template>
  <div>
    <PageTemplate @needToReloadEvents="onNeedToReloadEvents">
      <template v-slot:nav>
        <DateRangeSelect 
        @structure-selected="selectStructure" 
        :selectedStructure="getStructureDetails" 
        :availableStructures="getStructures" 
        @set-relative-mode="setRelativeMode" 
        :compensable="getIsCompensable"
        :relativeMode="getSelectedSensor ? getRelativeMode : undefined"
        />
      </template>
      <template v-slot:content>
        <!-- Modulo Struttura -->
        <Generic
          id="generic"
          :title="$t('structureTitle')"
          :subTitle="$t('structureSubTitle')"
          v-if="getStructureDetails && getStructureDetails.type === 'generic'"
          :selectedSensorFromParent="getSelectedSensor"
          :selectedStructure="getStructureDetails"
          @sensor-selected="setSelectedSensor"
          @refresh="fetchStructureData"
        />
        <Bridge id="bridge-inf" v-if="getStructureDetails && getStructureDetails.type !== 'generic'" :selectedSensors="[getSelectedSensor]" @sensor-selected="setSelectedSensor" :showMap="true" />
        <div id="status-row" v-if="false">
          <system-state v-if="getStructureDetails" :sensors="getStructureDetails.sensors" :selectedSensor="getSelectedSensor" :selectedTypeFromParent="getSensorType" />
          <alerts-state />
        </div>
        <!-- Overview Statistiche -->
        <OverviewInfo v-if="getStructureDetails && getSensorType !== 'analog-node'" />
        <!-- Grafico Displacement -->
        <LastEvents v-if="getStructureDetails" />
        <!-- Controllo Opzioni del Trend -->
        <!-- Grafici dei Trend -->
        <Trend v-if="false && getSensorType === 'deck' && getStructureDetails" />
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import OverviewInfo from "../../components/modules/dashboard/OverviewInfo.vue";
import Trend from "../../components/modules/dashboard/Trend.vue";
import LastEvents from "../../components/modules/dashboard/LastEvents.vue";
import Generic from "../../components/structures/Generic.vue";
import Bridge from "../../components/structures/Bridge.vue";
import systemState from "../../components/modules_V2/SystemState.vue";
import alertsState from "../../components/modules_V2/AlertsState.vue";

export default {
  name: "DashboardV2",
  components: {
    PageTemplate,
    DateRangeSelect,
    Bridge,
    OverviewInfo,
    Trend,
    Generic,
    LastEvents,
    systemState,
    alertsState
  },
  data() {
    return {
      loaded: true,
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSelectedSensor: "lastevents/setSelectedSensor",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      getEventsList: "lastevents/getEventsList",
      setRelativeMode: "lastevents/setRelativeMode"
      /* refreshSensorsStats: "structure/refreshSensorsStats", */
    }),
    async onNeedToReloadEvents(){
      await this.getEventsList()
    }
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorType: "lastevents/getSensorType",
      getSelectedSensor: "lastevents/getSelectedSensor",
      getUserType: "user/getUserType",
      getRelativeMode: "lastevents/getRelativeMode",
    }),
    getIsCompensable(){
      return this.getSelectedSensor && (this.getSelectedSensor.type === 'tiltmeter' || (this.getSelectedSensor.type === 'analog-node' && this.getSelectedSensor.rev === '2.0') )
    }
  }
};
</script>
<style scoped>
#status-row {
  width: 92%;
  margin: auto;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  column-gap: 20px;
  grid-template-rows: min-content;
}
#generic {
  margin-top: 20px;
}
#bridge-ow {
  max-width: 100vw;
}
</style>
