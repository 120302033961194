<i18n>
{
  "en": {
      "title": "Peak Component Particle Velocity.",
      "subTitle": "The PCPV represents the maximum absolute value over the considered time interval for each individual component of the velocity vector. For example, by setting the sensor cadence parameter to 2 minutes, the PCPV is calculated over a time window of 2 minutes. "
  },
  "it": {
      "title": "Peak Component Particle Velocity.",
      "subTitle": "La PCPV rappresenta il massimo del valore assoluto nell’intervallo di tempo considerato per ciascuna singola componente del vettore velocità. Impostando ad esempio il parametro cadenza del sensore a 2 minuti, la PCPV è calcolata su una finestra temporale di 2 minuti."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "ppv-pcpv-tool-balloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 490px;
}
</style>
