<i18n>
{
  "en": {
      "title": "Triaxial acceleration",
      "subTitle": "Each event is equivalent to a stress of the structure, when the activation threshold is exceeded. Acceleration acquisition, on the 3 axes, corresponds to 10 seconds after the activation threshold is exceeded."
  },
  "it": {
      "title": "Accelerazione triassiale",
      "subTitle": "Ogni evento corrisponde ad una sollecitazione della struttura, laddove venga superata la soglia di attivazione. L’acquisizione dell’accelerazione, sui 3 assi, corrisponde ai 10 secondi successivi al superamento della soglia di attivazione."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "accBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 400px;
}
</style>

