<template>
  <div class="over">
    <!-- Transmissions Wrapper -->
    <div id="tx-trend" class="trend-wrapper noselect">
      <!-- Info in Overlay -->
      <div class="infoi" v-show="!getLoadingStatus || this.getTxTraces.length == 0">
        <h2 v-if="this.getTxTraces.length == 0">{{ $t("message.noDataInRange") }}...</h2>
      </div>

      <!-- Trasmissioni Wrapper -->
      <div class="over-info">
        <div style="display: flex;">
          <h2>{{ $t("title.txTrend") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="showTxModal = true" @mouseleave="showTxModal = false" />
            <TxBalloon :visible="showTxModal" style="position: absolute; margin-left: 20px" @close="showTxModal = false" />
          </div>
        </div>
        <p>{{ $t("message.deckMultiData") }}</p>
        <img @click="downloadTXDetails" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <!-- Grafico Transmissions Trend -->
      <div>
        <LinePlot xTitle="Time (Days)" :yTitle="$t('title.txCount') + ' (N)'" :height="400" :traces="this.getTxTraces" />
      </div>
    </div>

    <!-- Temperature Wrapper -->
    <div id="temp-trend" class="trend-wrapper">
      <!-- Info in Overlay -->
      <div class="infoi" v-show="!getLoadingStatus || this.getTempTraces.length == 0">
        <h2 v-if="this.getTempTraces.length == 0">{{ $t("message.noDataInRange") }}...</h2>
      </div>
      <!-- Info Grafico -->
      <div class="over-info">
        <div style="display: flex;">
          <h2>{{ $t("title.tempTrend") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="showTempModal = true" @mouseleave="showTempModal = false" />
            <TempBalloon :visible="showTempModal" style="position: absolute; left: -140px" @close="showTempModal = false" />
          </div>
        </div>
        <p>{{ $t("message.deckMultiData") }}</p>
        <img @click="downloadTemperatureDetails" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <!-- Grafico Transmissions Trend-->
      <div>
        <LinePlot xTitle="Time (Days)" :yTitle="$t('dataType.temperature') + ' (°C)'" :height="400" :traces="this.getTempTraces" />
      </div>
    </div>

    <!-- Displacement Wrapper -->
    <div id="disp-trend" class="trend-wrapper">
      <div class="infoi" v-show="!getLoadingStatus || this.getDispTraces.length == 0">
        <h2 v-if="this.getDispTraces.length == 0">{{ $t("message.noDataInRange") }}...</h2>
      </div>
      <!-- Info Grafico -->
      <div class="over-info">
        <div style="display: flex;">
          <h2>{{ $t("title.displacementTrend") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="showDispModal = true" @mouseleave="showDispModal = false" />
            <DispBalloon :visible="showDispModal" style="position: absolute; margin-left: 20px" @close="showDispModal = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("message.deckMultiData") }}</p>
        <img @click="downloadDispDetails" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <!-- Grafico Displacement Trend -->
      <div>
        <LinePlot xTitle="Time (Days)" yTitle="Pk-Pk (mm)" :height="400" :traces="this.getDispTraces" />
      </div>
    </div>
  </div>
</template>
<script>
/*
	Modulo che contiene i tre grafici relativi ai trend.
*/

import LinePlot from "../../graphs/LinePlot.vue";
import TxBalloon from "../../tutorial/balloons/trend/TxTrendBl.vue";
import TempBalloon from "../../tutorial/balloons/trend/TempTrendBl.vue";
import DispBalloon from "../../tutorial/balloons/trend/DispTrendBl.vue";
import { exportCSVFile } from "../../../helpers/download.js";
import { mapGetters } from "vuex";

export default {
  name: "trend-module",
  components: {
    LinePlot,
    TxBalloon,
    TempBalloon,
    DispBalloon,
  },
  data() {
    return {
      showTxModal: false,
      showTempModal: false,
      showDispModal: false,
      loaded: true,
      baseTrace: {
        showlegend: false,
        mode: "lines+markers",
        connectgaps: true,
        line: {
          width: 3,
        },
        marker: {
          size: 8,
        },
      },
    };
  },
  methods: {
    calculateDispTrend: function (totalTrend) {
      let traces = [];
      // Per ogni sensore
      totalTrend.map((sensorItems) => {
        // Per ogni item di quel sensore
        if (sensorItems.length > 0) {
          let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
          basicTrace.name = sensorItems[0].eui.substr(sensorItems[0].eui.length - 5);
          basicTrace.x = sensorItems.map((a) => new Date(a.date));
          basicTrace.y = sensorItems.map((a) => a.stats["vibrations"].value);
          traces.push(basicTrace);
        }
      });
      return traces;
    },
    calculateTxTrend: function (totalTrend) {
      let traces = [];
      // Per ogni sensore
      totalTrend.map((sensorItems) => {
        if (sensorItems.length > 0) {
          // Per ogni item di quel sensore
          let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
          basicTrace.name = sensorItems[0].eui.substr(sensorItems[0].eui.length - 5);
          basicTrace.x = sensorItems.map((a) => new Date(a.date));
          basicTrace.y = sensorItems.map((a) => a.stats["transmissions"].value);
          traces.push(basicTrace);
        }
      });
      return traces;
    },
    calculateTempTrend: function (totalTrend) {
      let traces = [];
      // Per ogni sensore
      totalTrend.map((sensorItems) => {
        // Per ogni item di quel sensore
        if (sensorItems.length > 0) {
          let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
          basicTrace.name = sensorItems[0].eui.substr(sensorItems[0].eui.length - 5);
          basicTrace.x = sensorItems.map((a) => new Date(a.date));
          basicTrace.y = sensorItems.map((a) => a.stats["temperatures"].value);
          traces.push(basicTrace);
        }
      });
      return traces;
    },
    downloadTXDetails: function () {
      let totalEvents = [];
      let headers = {};
      let title = "TxStats";
      if (this.getTxTraces) {
        this.getTxTraces.map(function (singleSensorTraces) {
          for (let i = 0; i < singleSensorTraces.x.length; i++) {
            const myDate = new Date(singleSensorTraces.x[i]);
            const date = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
            totalEvents.push({
              device: singleSensorTraces.name,
              date: new Date(date).toLocaleString().replace(/,/g, ""),
              tx: parseInt(singleSensorTraces.y[i]),
            });
          }
        });
        headers = {
          eui: "Device",
          date: "Data (Locale)",
          tx: "Count",
        };
        exportCSVFile(headers, totalEvents, title);
      }
    },
    downloadTemperatureDetails: function () {
      let totalEvents = [];
      let headers = {};
      let title = "TempStats";
      if (this.getTempTraces) {
        this.getTempTraces.map(function (singleSensorTraces) {
          for (let i = 0; i < singleSensorTraces.x.length; i++) {
            const myDate = new Date(singleSensorTraces.x[i]);
            const date = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
            totalEvents.push({
              device: singleSensorTraces.name,
              date: new Date(date).toLocaleString().replace(/,/g, ""),
              temp: parseFloat(singleSensorTraces.y[i]).toFixed(2),
            });
          }
        });
        headers = {
          eui: "Device",
          date: "Data (Locale)",
          temp: "Temperature °C",
        };
        exportCSVFile(headers, totalEvents, title);
      }
    },
    downloadDispDetails: function () {
      let totalEvents = [];
      let headers = {};
      let title = "DispStats";
      if (this.getDispTraces) {
        this.getDispTraces.map(function (singleSensorTraces) {
          for (let i = 0; i < singleSensorTraces.x.length; i++) {
            const myDate = new Date(singleSensorTraces.x[i]);
            const date = new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
            totalEvents.push({
              device: singleSensorTraces.name,
              date: new Date(date).toLocaleString().replace(/,/g, ""),
              disp: parseFloat(singleSensorTraces.y[i]).toFixed(2),
            });
          }
        });
        headers = {
          eui: "Device",
          date: "Data (Locale)",
          disp: "Displacement Pk-Pk [mm]",
        };
        exportCSVFile(headers, totalEvents, title);
      }
    },
  },
  computed: {
    ...mapGetters("trend", ["getStats", "getLoadingStatus"]),
    getDispTraces: function () {
      if (this.loaded && this.getStats.length > 0) return this.calculateDispTrend(this.getStats);
      return [];
    },
    getTxTraces: function () {
      if (this.loaded && this.getStats.length > 0) return this.calculateTxTrend(this.getStats);

      return [];
    },
    getTempTraces: function () {
      if (this.loaded && this.getStats.length > 0) return this.calculateTempTrend(this.getStats);

      return [];
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
}
/* Main Wrapper */
.over {
  min-width: 1000px;
  width: 92%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
  margin-left: 4%;
  margin-right: 4%;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .over {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
/* Wrapper del singolo modulo di trend */
.trend-wrapper {
  position: relative;
  height: 485px;
  margin-top: 35px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

/* ID displacement trend */
#disp-trend {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

/* ID transmission trend */
#tx-trend {
  grid-row: 1;
  grid-column: 1;
}
/* ID temperature trend */
#temp-trend {
  grid-row: 1;
  grid-column: 2;
}
/* Info in alto a sinistra sul tipo di grafico */
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content 10fr;
}

.download-btn {
  cursor: pointer;
  grid-row: 1/3;
  height: 18px;
  justify-self: end;
  margin-right: 32px;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  grid-column: 2;
  border-radius: 10px;
}
.pointer{
  margin-left: 6px;
}
</style>
