<template>
  <div id="ps-root">
    <ul class="noselect">
      <li @click="backPage">{{ backChar }}</li>
      <li v-for="page in compPageNumber" :key="page" :class="[value === page ? 'selected' : '']" @click="selectPage(page)">{{ page }}</li>
      <li @click="nextPage">{{ forwardChar }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "page-select",
  props: {
    value: Number,
    pagesNumber: Number,
    maxVisiblePages: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      backChar: "<",
      forwardChar: ">",
      startPage: 1,
    };
  },
  methods: {
    backPage() {
      this.startPage = this.startPage > 1 ? this.startPage - 1 : this.startPage;
    },
    nextPage() {
      this.startPage = this.startPage + this.maxVisiblePages > this.pagesNumber ? this.startPage : this.startPage + 1;
    },
    selectPage(page) {
      this.$emit("input", page);
    },
    numberRange(start, end) {
      return new Array(end - start).fill().map((d, i) => i + start);
    },
  },
  computed: {
    compPageNumber() {
      const totalVisiblePages = Math.min(this.maxVisiblePages, this.pagesNumber);
      return this.numberRange(this.startPage, totalVisiblePages + this.startPage);
    },
  } /* ,
  watch: {
      pagesNumber
  } */,
};
</script>
<style scoped>
ul {
  display: flex;
  color: white;
  width: fit-content;
}
li {
  cursor: pointer;
  font-size: 14px;
  color: rgb(32, 34, 47);
  background-color: rgb(80, 84, 105);
  border-radius: 11px;
  width: 22px;
  height: 22px;
  list-style-type: none;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.selected {
  color: white;
  background-color: rgb(21, 146, 230);
}
</style>