<template>
  <div id="root" class="generic-wrapper">
    <div id="overlay" v-if="this.loading">
      <h3>{{ $t("message.loading") }}......</h3>
    </div>
    <header>
      <h2>{{ $t("message.alertList") }}</h2>
      <img src="../../../public/assets/icons/info.svg" alt />
    </header>
    <main>
      <!-- Tabella Sensori -->
      <div class="info-tab">
        <table style="width: 100%">
          <tr class="tr-head">
            <th>{{ $t("message.sensor") }}</th>                                                             <!-- Tutti -->
            <th>{{ $t("message.date") }}</th>                                                               <!-- Tutti -->
            <th>{{ $t("message.threshold") }}</th>                                                          <!-- Tutti tranne Tilt -->
            <th v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">[Phi]</th>                        <!-- Solo Tilt -->
            <th v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">[Theta]</th>                      <!-- Solo Tilt -->
            <th>{{ $t("message.value") }}</th>                                                              <!-- Tutti tranne Tilt -->
            <th v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">[Phi]</th>                        <!-- Solo Tilt -->
            <th v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">[Theta]</th>                      <!-- Solo Tilt -->
            <th v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">{{ $t("message.channel") }}</th>  <!-- Solo Nodi -->
            <!-- Nodi -->
          </tr>
          <tr v-for="alert in filteredAlerts" :key="alert._id" class="tr-content" @click="openEvent(alert.eventID)">
            <td>{{ alert.eui.substr(alert.eui.length - 5) }}</td>
            <td>{{ new Date(alert.date).toLocaleString() }}</td>
            <td>{{ alert.threshold || "-" }}</td>
            <td v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">
              {{ (alert.thresholds && alert.thresholds.some((an) => an.angle === "Phi")) ? alert.thresholds.filter((an) => an.angle === "Phi")[0].threshold.toFixed(6) : "-" }}
            </td>
            <td v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">
              {{ (alert.thresholds && alert.thresholds.some((an) => an.angle === "Theta")) ? alert.thresholds.filter((an) => an.angle === "Theta")[0].threshold.toFixed(6) : "-" }}
            </td>
            <td>{{ alert.valueExceeded ? alert.valueExceeded.toFixed(4) : "-" }}</td>
            <td v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">
              {{ (alert.anglesExceeded && alert.anglesExceeded.some((an) => an.angle === "Phi")) ? alert.anglesExceeded.filter((an) => an.angle === "Phi")[0].peak.toFixed(6) : "-" }}
            </td>
            <td v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">
              {{ (alert.anglesExceeded && alert.anglesExceeded.some((an) => an.angle === "Theta")) ? alert.anglesExceeded.filter((an) => an.angle === "Theta")[0].peak.toFixed(6) : "-" }}
            </td>
            <td v-if="filteredAlerts[0] && filteredAlerts.some((f) => f)">{{ alert.channelName || "-" }}</td>
          </tr>
        </table>
      </div>
    </main>
    <footer>
      <page-select v-model="page" :pagesNumber="pagesNumber" />
    </footer>
    <!-- Settings -->
    <transition name="settings">
      <modal-event />
    </transition>
  </div>
</template>
<script>
import PageSelect from "../ui/PageSelect.vue";
/* import pagination from "../../helpers/pagination"; */
import ModalEvent from "./ModalEvent.vue";
import api from "../../services/api";
import { mapGetters } from "vuex";
/* import math from "../../helpers/math"; */

export default {
  name: "AlertsState",
  components: {
    PageSelect,
    ModalEvent,
  },
  props: {
    sensorsType: String,
  },
  data() {
    return {
      loading: false,
      page: 1,
      total: 5,
      itemsPerPage: 8,
      alertsFromAPI: [],
    };
  },
  methods: {
    fetchAlerts() {
      if (this.getDateRange[0] && this.getSelectedStructure._id) {
        console.log("Fetch allarmi")
        this.loading = true;
        api
          .getAlertList(this.getSelectedStructure._id, this.itemsPerPage, this.page, this.getDateRange[0], this.getDateRange[1])
          .then((resp) => {
            this.alertsFromAPI = resp.alerts;
            this.total = resp.countAlerts;
            this.loading = false;
          })
          .catch((err) => console.log(err));
      }
    },
    openEvent(eventID) {
      console.log("Apro dettagli evento", eventID);
    },
  },
  computed: {
    ...mapGetters("lastevents", ["getDateRange"]),
    ...mapGetters("structure", ["getSensors", "getSelectedStructure"]),
    filteredAlerts() {
      /* const paginatedArray = pagination.paginateArray(this.alertsFromAPI, this.itemsPerPage);
      return paginatedArray[this.page - 1]; */
      return this.alertsFromAPI;
    },
    pagesNumber() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
  },
  watch: {
    getDateRange: async function (newValue, oldValue) {
      if (!oldValue[0] || (newValue[0] && newValue[1] && oldValue[0] && oldValue[1] && (newValue[0] != oldValue[0] || newValue[1] != oldValue[1]))) {
        this.fetchAlerts();
      }
    },
    getSelectedStructure: function (newValue, oldValue) {
      // Prima esecuzione oppure cambiata veramente struttura
      if (!oldValue || (newValue && oldValue && newValue._id != oldValue._id)) {
        this.fetchAlerts();
      }
    },
    page: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.fetchAlerts();
      }
    },
  },
};
</script>
<style scoped>
#root {
  width: 100%;
  display: grid;
  grid-template-rows: 50px 10fr 50px;
  grid-template-columns: 10fr;
}
header {
  border-bottom: solid 2px white;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  z-index: 2;
}
img {
  cursor: pointer;
}
h2 {
  font-weight: 400;
  font-size: 1.2em;
  padding-right: 40px;
}
h3 {
  color: white;
}

/* overlay */
#overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: black;
  opacity: 0.7;
  border-radius: 10px;
}
main {
  min-height: 340px;
}
.info-tab {
  margin: 20px;
  overflow: scroll;
}
table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
tr {
  white-space: nowrap;
}
.tr-head > th {
  color: rgb(198, 198, 198);
  padding: 0 8px;
}
th {
  font-weight: 400;
}
td {
  color: white;
  border: solid 2px transparent;
  border-style: solid none;
  padding: 3px;
  background-color: red;
  cursor: pointer;
}
tr.selected > td {
  border-color: yellow;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.tr-content {
  font-size: 0.9em;
}
</style>