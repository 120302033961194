<i18n>
{
  "en": {
    "nodeV2": {
      "dataTitle": "Single Channel Node",
      "dataSubtitle": "Trend of the probe connected to the selected Analog Node.",
      "dataInfo": "The graph displays the measurement provided by the probe connected to the node. If the mode is 'raw' the raw data provided by the probe is shown. If the mode is 'converted', the graph shows the actual measurement.",
      "ntcInfo": "Graph showing the data detected by the NTC inside the probe. If the setting mode is Raw Data, it shows the Raw Data detected by the NTC. If the the Setting mode is Custom, the data is converted into the actual measure.",
      "internalNtcTitle": "NTC",
      "internalNtcSubtitle": "Trend of the data detected by the NTC of the probe.",
      "temperatureTitle": "Temperature trend",
      "temperatureSubtitle": "Temperature trend of the internal NTC."
    }
  },
  "it": {
    "nodeV2": {
      "dataTitle": "Single Channel Node",
      "dataSubtitle": "Andamento della sonda collegata al Single Channel Node selezionato.",
      "dataInfo": "Il grafico corrisponde alla misura fornita dalla sonda connessa al nodo. Se la modalità è 'grezzi' è mostrato il dato grezzo fornito dalla sonda. Se la modalità è 'convertiti', il grafico corrisponde alla misura effettiva.",
      "ntcInfo": "Grafico che mostra la misura fornita dall'NTC interno alla sonda. Se la modalità è dati grezzi, è mostrato il dato grezzo fornito dall'NTC. Se la modalità è Custom, il dato che appare nel grafico è convertito nella misura effettiva.",
      "internalNtcTitle": "NTC",
      "internalNtcSubtitle": "Andamento della temperatura dell’NTC interno.",
      "temperatureTitle": "Andamento delle temperatura",
      "temperatureSubtitle": "Andamento della temperatura rilevato dall’NTC della sonda."
    }
  }
}
</i18n>
<template>
  <div class="over">
    <!-- Module Container -->
    <div id="disp-peak" class="peak-wrapper noselect">
      <!-- Wrapper Info in Overlay (ad eccezione del vibrometro che utilizza un modulo custom) -->
      <div class="infoi" v-show="!this.getLoadingStatus || !this.getIsThereData && getSensorType !== 'vibrometer'">
        <h2 v-if="!this.getIsThereData && this.getLoadingStatus">{{ $t("message.noDataForSensor") }}...</h2>
        <h2 v-else-if="this.getSelectedSensor">{{ $t("message.loading") }}</h2>
        <h2 v-else>No selected sensor...</h2>
      </div>

      <!-- Displacement e Crackmeter -->
      <div v-if="getSelectedSensor && (getSensorType === 'deck' || getSensorType === 'crackmeter')">
        <div class="main-wrap over-info" v-if="this.getSelectedSensor">
          <div style="display: flex;">
            <h2 v-if="getSelectedSensor.type === 'deck'">{{ $t("graphTitlesOverview.deck") }}</h2>
            <h2 v-if="getSelectedSensor.type === 'crackmeter'">{{ $t("message.crackGraph") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
              <DeckBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            </div>
          </div>
          <p v-if="getSensorType === 'crackmeter'" class="first-block">{{ $t("message.dataOfSelectedDevice") }}</p>
          <p v-if="getSensorType === 'deck'" class="first-block">{{ $t("graphSubTitlesOverview.deck") }}</p>
        </div>
        <LinePlot v-if="getSelectedSensor && getSensorType === 'deck'" :height="this.plotHeight" :traces="this.getDispTrace" xTitle="Time (Hr)" yTitle="Pk - Pk (mm)" />
        <LinePlot
          v-if="getSelectedSensor && getSensorType === 'crackmeter' && !getSelectedSensor.subType"
          :height="this.plotHeight"
          :traces="this.getCrackTrace"
          xTitle="Time (Hr)"
          :yTitle="$t('dataType.crack') + ' (mm)'"
        />
        <LinePlot
          v-if="getSelectedSensor && getSensorType === 'crackmeter' && getSelectedSensor.subType && getSelectedSensor.subType === 'loadcell'"
          :height="this.plotHeight"
          :traces="this.getLoadTrace"
          xTitle="Time (Hr)"
          :yTitle="'Load (N)'"
        />
      </div>

      <!-- Nodo -->
      <div v-if="getSelectedSensor && (getSensorType === 'analog-node' || getSensorType === 'digital-node') && getSensorRev === '1.0'">
        <div class="main-wrap over-info" v-if="this.getSelectedSensor">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesOverview.analogNode") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
              <AnNodeBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesOverview.analogNode") }}</p>
        </div>
        <div class="four-plot">
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="this.plotHeight"
              :traces="this.getAnNodeCH1Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[0].name + ' [' + (getSelectedSensor.userConfig.channels[0].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="this.plotHeight"
              :traces="this.getAnNodeCH2Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[1].name + ' [' + (getSelectedSensor.userConfig.channels[1].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="this.plotHeight"
              :traces="this.getAnNodeCH3Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[2].name + ' [' + (getSelectedSensor.userConfig.channels[2].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="this.plotHeight"
              :traces="this.getAnNodeCH4Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[3].name + ' [' + (getSelectedSensor.userConfig.channels[3].mesUnit || ' - ') + ']'"
            />
          </div>
        </div>
      </div>
      <!-- Analog Node v2 -->
      <div v-if="getSelectedSensor && getSensorType === 'analog-node' && getSensorRev === '2.0'">
        <!-- Data Graph -->
        <div class="main-wrap-2">
          <div class="d-flex align-items-center mb16">
            <move-text p b class="text-align-left mr8"> {{ `${$t("nodeV2.dataTitle")} ${getShortEui(getSelectedSensor.eui)}` }}  </move-text>
            <move-ballon width="400px">
              <template #content>
                <p>{{ $t('nodeV2.dataInfo') }}</p>
              </template>
            </move-ballon>
          </div>
          <move-text p b class="text-align-left">{{ getNodeV2ProbeName }} </move-text>
          <move-text p class="text-align-left">{{ $t('nodeV2.dataSubtitle') }} </move-text>
        </div>
        <div>
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :yTitle="getNodeV2MesUnit"
            :traces="getAnalogNodeV2Trace"
            showLegend
            :legend="{
              orientation: 'h'
            }"
          />
        </div>
        <!-- Temperature Graph -->
        <div v-if="getNodeV2NtcEnable && getNodeV2Mode === 'raw'">
          <div class="main-wrap-2">
            <div class="d-flex align-items-center">
              <move-text p b class="text-align-left mr8"> {{ $t('nodeV2.internalNtcTitle') }} </move-text>
              <move-ballon width="400px">
                <template #content>
                  <p>{{ $t('nodeV2.ntcInfo') }}</p>
                </template>
              </move-ballon>
            </div>
            <move-text p class="text-align-left"> {{ $t('nodeV2.internalNtcSubtitle') }} </move-text>
          </div>
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :traces="getAnalogNodeV2RawTemperatureTrace"
            yTitle="Ω"
          />
        </div>
        <div v-if="getNodeV2NtcEnable && getNodeV2Mode === 'custom'">
          <div class="main-wrap-2">
            <div class="d-flex align-items-center">
              <move-text p b class="text-align-left mr8"> {{ $t('nodeV2.temperatureTitle') }}  </move-text>
              <move-ballon width="400px">
                <template #content>
                  <p>{{ $t('nodeV2.ntcInfo') }}</p>
                </template>
              </move-ballon>
            </div>
            <move-text p class="text-align-left"> {{ $t('nodeV2.temperatureSubtitle') }}  </move-text>
          </div>
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :traces="getAnalogNodeV2ConvertedTemperatureTrace"
            yTitle="°C"
          />
        </div>
      </div>

      <!-- Tiltmeter -->
      <div v-if="getSelectedSensor && getSensorType === 'tiltmeter'">
        <div class="main-wrap over-info" v-if="this.getSelectedSensor">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesOverview.tiltmeter") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
              <AnglesBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesOverview.tiltmeter") }}</p>
        </div>
        <div class="double-small-graphs">
          <div class="small-graph">
            <LinePlot
              v-if="getSelectedSensor && getSensorType === 'tiltmeter'"
              :height="this.plotHeight"
              :traces="this.getTiltTraceSub1"
              xTitle="Time (Hr)"
              :yTitle="'Δφ ' + $t('dataType.tilt') + ' ' + (this.mesUnit && this.mesUnit.degrees === 'mm/m' ? '[mm/m]' : '°')"
            />
          </div>
          <div class="small-graph">
            <LinePlot
              v-if="getSelectedSensor && getSensorType === 'tiltmeter'"
              :height="this.plotHeight"
              :traces="this.getTiltTraceSub2"
              xTitle="Time (Hr)"
              :yTitle="'Δϑ ' + $t('dataType.tilt') + ' ' + (this.mesUnit && this.mesUnit.degrees === 'mm/m' ? '[mm/m]' : '°')"
            />
          </div>
        </div>
      </div>

      <!-- Accelerationi -->
      <div v-if="getSelectedSensor && getSensorType === 'accelerometer'">
        <div class="main-wrap over-info" v-if="this.getSelectedSensor">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesOverview.accelerometer") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
              <AccBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesOverview.accelerometer") }}</p>
        </div>
        <div class="multi-plot">
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="this.plotHeight" :traces="this.getAccXTrace" xTitle="Time (Hr)" yTitle="Peak to Peak X (mg)" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="this.plotHeight" :traces="this.getAccYTrace" xTitle="Time (Hr)" yTitle="Peak to Peak Y (mg)" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="this.plotHeight" :traces="this.getAccZTrace" xTitle="Time (Hr)" yTitle="Peak to Peak Z (mg)" />
          </div>
        </div>
      </div>

      <!-- Vibrometer -->
      <div v-if="getSelectedSensor && getSensorType === 'vibrometer'">
        <PPVNormativeChart :minimalView="true" :PPVs="getPPVs" :sensorObj="getSelectedSensor" :last24Hrs="true" />
      </div>
    </div>

    <!-- Grafico delle temperature mostrato se è selezionato un Accelerometro, Inclinometro o Estensimetro -->
    <div id="temp-peak" class="peak-wrapper temp-wrapper noselect" v-if="getSelectedSensor && (getSensorType === 'accelerometer' || getSensorType === 'tiltmeter' || getSensorType === 'crackmeter')">
      <!-- Wrapper Info in Overlay -->
      <div class="infoi" v-show="!this.getLoadingStatus || !this.getIsThereData">
        <h2 v-if="!this.getIsThereData && this.getLoadingStatus && getSensorType !== 'vibrometer'">{{ $t("message.noDataForSensor") }}...</h2>
        <h2 v-else-if="getSensorType !== 'vibrometer'">{{ $t("message.loading") }}...</h2>
      </div>
      <div class="main-wrap over-info" v-if="this.getSelectedSensor">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesOverview.temperature") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisibleTemperature = true" @mouseleave="isInfoVisibleTemperature = false" />
            <TemperatureBalloon :visible="isInfoVisibleTemperature" style="position: absolute; margin-left: 20px" @close="isInfoVisibleTemperature = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesOverview.temperature") }}</p>
      </div>
      <div>
        <LinePlot v-if="getSelectedSensor" :height="this.subPlotHeight" :traces="this.getTemperaturesTrace" xTitle="Time (Hr)" yTitle="Temperature (°C)" />
      </div>
    </div>
  </div>
</template>
<script>
/* 
Modulo che grafica gli eventi delle ultime 24h relative al sensore selezionato.
Attualmente sono previsti "tilt", "displacement" e "acceelrations"
*/

import LinePlot from "../../graphs/LinePlot.vue";
import { mapGetters, mapActions } from "vuex";
import AnglesBalloon from "../../tutorial/balloons/overview/PhiThetaBl.vue";
import DeckBalloon from "../../tutorial/balloons/overview/DynamicBl.vue";
import AnNodeBalloon from "../../tutorial/balloons/overview/AnNodeBl.vue";
import AccBalloon from "../../tutorial/balloons/overview/TriAccBl.vue";
import TemperatureBalloon from "../../tutorial/balloons/overview/TemperatureBl.vue";
import PPVNormativeChart from "../../modules_V2/PPVNormativeCharts.vue";
import { useTimestampFieldBySensor } from "../../../helpers/sensors";
import math from "../../../helpers/math.js";
import { getShortEui } from "../../../helpers/sensors";
var mql = window.matchMedia("screen and (max-width: 1499px)"); //Media per l'adattamento agli schermi

export default {
  name: "last-events",
  components: {
    LinePlot,
    AnglesBalloon,
    DeckBalloon,
    AnNodeBalloon,
    AccBalloon,
    TemperatureBalloon,
    PPVNormativeChart,
  },
  data() {
    return {
      loaded: false,
      firstExec: true,
      plotHeight: 600,
      subPlotHeight: 350,
      isInfoVisible: false,
      isInfoVisibleTemperature: false,
      /* Trace di base a cui vengono aggiunti attributi in base al grafico */
      baseTrace: {
        connectgaps: true,
        line: {
          color: "rgb(17, 205, 239)",
          width: 2,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 8,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  methods: {
    ...mapActions("lastevents", ["setDateRange", "getEventsList", "setSelectedSensor"]),
    /* Handler per la reponsiveness dei grafici */
    responsiveScreenHandler(e) {
      if (e.matches) {
        this.plotHeight = 460;
        this.subPlotHeight = 290;
      } else {
        this.plotHeight = 600;
        this.subPlotHeight = 350;
      }
    },
    getShortEui: getShortEui
  },
  computed: {
    ...mapGetters("lastevents", [
      "getSensorType",
      "getSelectedSensor",
      "getDateRange",
      "getSensorType",
      "getAccelerations",
      "getVibrations",
      "getTemperatures",
      "getTilts",
      "getAnalogReadings",
      "getCracks",
      "getBaseValue",
      "getRelativeMode",
      "getLoadingStatus",
      "getIsThereData",
      "getPPVs",
    ]),
    ...mapGetters("structure", ["getSensors"]),
    ...mapGetters("user", ["getInterface"]),
    getNovdeV2Channel0() {
      return (
        this.getSelectedSensor &&
        this.getSelectedSensor.userConfig &&
        this.getSelectedSensor.userConfig.channels &&
        this.getSelectedSensor.userConfig.channels.length &&
        this.getSelectedSensor.userConfig.channels[0]
      );
    },
    getNodeV2MesUnit() {
      return this.getNovdeV2Channel0 && this.getNovdeV2Channel0.mesUnit;
    },
    getNodeV2ProbeName() {
      return this.getNovdeV2Channel0 && this.getNovdeV2Channel0.name;
    },
    getNodeV2NtcEnable() {
      return (
        this.getNovdeV2Channel0 &&
        this.getNovdeV2Channel0.externalNtc &&
        this.getNovdeV2Channel0.externalNtc.enabled
      );
    },
    getNodeV2Mode(){
      return (
        this.getNovdeV2Channel0 &&
        this.getNovdeV2Channel0.mode
      );
    },
    getSensorRev(){
      return this.getSelectedSensor && this.getSelectedSensor.rev 
    },  
    getSensorEui(){
      return this.getSelectedSensor && this.getSelectedSensor.eui 
    },  
    mesUnit() {
      return this.getInterface ? this.getInterface.dimensions : null;
    },
    /* Restituisce un oggetto contente 4 array relativi alle date ed alle accelerazioni rispettive sui 3 assi (converto in mg) */
    getAxixAccelerations: function() {
      return {
        dates: this.getAccelerations.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date)),
        x: this.getAccelerations.map((event) => (event.acceleration.xPeak * 1000).toFixed(3)),
        y: this.getAccelerations.map((event) => (event.acceleration.yPeak * 1000).toFixed(3)),
        z: this.getAccelerations.map((event) => (event.acceleration.zPeak * 1000).toFixed(3)),
      };
    },
    /* Restituisce la trace relativa alle accelerazione sull'asse X */
    getAccXTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAxixAccelerations.dates;
      basicTrace.y = this.getAxixAccelerations.x;
      return [basicTrace];
    },
    /* Restituisce la trace relativa alle accelerazione sull'asse Y */
    getAccYTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAxixAccelerations.dates;
      basicTrace.y = this.getAxixAccelerations.y;
      return [basicTrace];
    },
    /* Restituisce la trace relativa alle accelerazione sull'asse Z */
    getAccZTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAxixAccelerations.dates;
      basicTrace.y = this.getAxixAccelerations.z;
      return [basicTrace];
    },
    getAnalogNodeV2Trace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));

      //TRACE Scheduled +  triggered
      basicTrace.name = 'Scheduled + triggered'
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));

      basicTrace.y = this.getAnalogReadings.map((event) => event.value === undefined ? null : event.value);
      basicTrace.mode = 'lines'

      basicTrace.hovertemplate = 
        "Value: %{y} %{yaxis.title.text}<br>" +
        "Date: %{x}<br><br>" +
        "<extra></extra>"

      //ONLY Scheduled trace
      let scheduledTrace = JSON.parse(JSON.stringify(this.baseTrace));
      scheduledTrace.line.color = 'green'
      scheduledTrace.name = 'Scheduled trend'
      scheduledTrace.mode = 'lines'
      scheduledTrace.visible = 'legendonly'

      scheduledTrace.x = this.getAnalogReadings
        .filter((event) => event.type === 'scheduled')
        .map((event) => new Date(event.timestamp || event.date));
      scheduledTrace.y = this.getAnalogReadings
        .filter((event) => event.type === 'scheduled')
        .map((event) => event.value === undefined ? null : event.value)
      
      scheduledTrace.hovertemplate = 
        "Value: %{y} %{yaxis.title.text}<br>" +
        "Date: %{x}<br><br>" +
        "<extra></extra>"

      //ONLY Triggered trace
      let triggeredTrace = JSON.parse(JSON.stringify(this.baseTrace));

      triggeredTrace.marker.size = 3
      triggeredTrace.marker.color = 'ff5050'
      triggeredTrace.name = 'Triggered markers'
      triggeredTrace.mode = 'markers';

      triggeredTrace.customdata = this.getAnalogReadings.filter((event) => event.type === 'triggered_acc').map(event => {
        const peakValue = event.acceleration.peakValue === undefined ? null : event.acceleration.peakValue;
        const rmsValue = event.acceleration.rmsValue === undefined ? null : event.acceleration.rmsValue;
        const peakAxis = event.acceleration.peakAxis === undefined ? null : event.acceleration.peakAxis;
        const rmsAxis = event.acceleration.rmsAxis === undefined ? null : event.acceleration.rmsAxis;
        return `<i>peakValue</i>: ${peakValue}<br>` +
              `<i>rmsValue</i>: ${rmsValue}<br>` +
              `<i>peakAxis</i>: ${peakAxis}<br>` +
              `<i>rmsAxis</i>: ${rmsAxis}<br>`
      })

      triggeredTrace.hovertemplate = 
            "Value: %{y} %{yaxis.title.text}<br>" +
            "Date: %{x}<br><br>" +
            "<b>Acceleration</b><br>" +
            "%{customdata}" +
            "<extra></extra>"

      triggeredTrace.x = this.getAnalogReadings
        .filter((event) => event.type === 'triggered_acc')
        .map((event) => new Date(event.timestamp || event.date));
      triggeredTrace.y = this.getAnalogReadings
        .filter((event) => event.type === 'triggered_acc')
        .map((event) => event.value === undefined ? null : event.value);
      return [basicTrace, triggeredTrace, scheduledTrace ];

    },
    getAnalogNodeV2ConvertedTemperatureTrace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.convertedTemperature === undefined ? null : event.convertedTemperature);
      return [basicTrace];
    },
    getAnalogNodeV2RawTemperatureTrace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.probeTemperature === undefined ? null : event.probeTemperature);
      return [basicTrace];
    },
    getAnNodeCH1Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData[0]);
      return [basicTrace];
    },
    getAnNodeCH2Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData[1]);
      return [basicTrace];
    },
    getAnNodeCH3Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData[2]);
      return [basicTrace];
    },
    getAnNodeCH4Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData[3]);
      return [basicTrace];
    },
    /* Restituisce la trace relativa ai displacement */
    getDispTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getVibrations) {
        basicTrace.x = this.getVibrations.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getVibrations.map((event) => (event.payload.piccoMax - event.payload.piccoMin).toFixed(2));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getTiltTraceSub1: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTilts) {
        basicTrace.x = this.getTilts.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTilts.map((event) => (this.mesUnit && this.mesUnit.degrees === "mm/m" ? math.degreesToMM(event.phiAngle).toFixed(6) : event.phiAngle.toFixed(6)));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getTiltTraceSub2: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTilts) {
        basicTrace.x = this.getTilts.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTilts.map((event) => (this.mesUnit && this.mesUnit.degrees === "mm/m" ? math.degreesToMM(event.thetaAngle).toFixed(6) : event.thetaAngle.toFixed(6)));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getCrackTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getCracks) {
        let offset = this.getRelativeMode && this.getBaseValue ? this.getBaseValue : 0;
        basicTrace.x = this.getCracks.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getCracks.map((event) => event.sample - offset);
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getLoadTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getCracks) {
        basicTrace.x = this.getCracks.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getCracks.map((event) => (event.sample / (3.92 * Math.pow(10, -3)) - 6400) * 11.71875);
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getTemperaturesTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTemperatures) {
        basicTrace.x = this.getTemperatures.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTemperatures.filter((event) => event.temperature < 100 && !isNaN(event.temperature)).map((event) => event.temperature.toFixed(2));
      }
      return [basicTrace];
    },
  },
  async mounted() {
    // Verifico la dimensione della finestra e aggiungo listener per gestire il ridimensionamento
    this.responsiveScreenHandler(mql);
    mql.addEventListener("change", () => {
      this.responsiveScreenHandler;
    });
    this.setDateRange([
      // Setto il dateRange alle ultime 24h
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      new Date(),
    ]);
  },
  watch: {
    getSelectedSensor: {
      handler: async function(newSensor, oldSensor) {
        // Init and Date already ok
        if (!this.firstExec && ((newSensor && oldSensor && newSensor.eui != oldSensor.eui) || !oldSensor)) {
          this.loaded = false;
          await this.getEventsList();
          this.loaded = true;
        }
        this.firstExec = false;
        // Se ho selezioanto un vibrometro setto la prima data coerentemente con l'ultimo lastChange della norma
        if (newSensor && newSensor.type === "vibrometer") {
          let lastDate = new Date();
          // Adeguo la prima data del fetch coerentemente con l'ultima config del sensore (prendo il min. tra lei e 2 giorni fa)
          let firstDate = new Date();
          firstDate.setDate(firstDate.getDate() - 1);
          const lastChange = newSensor.userConfig.threshold.lastChange;
          const d = new Date(lastChange ? Math.max(firstDate, new Date(lastChange)) : firstDate);
          this.firstValidDate = d;
          //const currentLastDate = this.getDateRange[1];
          await this.setDateRange([d, lastDate]);
        }
      },
      deep: true,
    },
    getDateRange: {
      handler: async function() {
        // Init and Sensor already ok
        if (!this.firstExec) {
          this.loaded = false;
          await this.getEventsList();
          this.loaded = true;
        }
        this.firstExec = false;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
  color: rgb(255, 255, 255);
}
h1 {
  color: white;
  font-size: 1em;
}
/* Main Wrapper */
.over {
  min-width: 1000px;
  width: 92%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
  margin-left: 4%;
  margin-right: 4%;
}

/* Wrapper Evento */
.peak-wrapper {
  position: relative;
  margin-top: 30px;
  padding-bottom: 0;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.double-small-graphs {
  display: grid;
  grid-template-columns: 5fr 5fr;
  column-gap: 40px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

/* Wrapper Temperature */
.temp-wrapper {
  height: 450px;
}

/* Contenitore grafico singolo */
.single-plot {
  width: 33%;
  grid-template-columns: 100%;
  display: grid;
}

/* Contenitore grafici multipli */
.multi-plot {
  display: flex;
}
.four-plot {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: 5fr 5fr;
}
.four-plot .single-plot {
  width: 100%;
}

/* ID Evento principale */
#disp-peak {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  min-height: 200px;
}

/* ID temperature */
#temp-peak {
  width: 100%;
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 3;
}
/* Divisori */
.horiz-divider {
  height: 2px;
  background-color: rgba(80, 84, 105, 1);
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 90%;
}

/* Info in alto sinistra sul tipo di grafico */
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
  margin-top: 1.5%;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .over {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .temp-wrapper {
    height: 390px;
  }
}

.help-icon-container {
  display: flex;
  align-items: center;
}
.help-icon-container > img {
  margin: 0 6px;
}

.pointer{
  margin-left: 6px;
}

.main-wrap-2 {
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}
</style>
