<i18n>
{
  "en": {
      "title": "Spectrum Peak Frequency.",
      "subTitle": "Frequency corresponding to the peak of the maximum amplitude spectrum."
  },
  "it": {
      "title": "Frequenza del picco dello spettro.",
      "subTitle": "Frequenza corrispondente al picco dello spettro di massima ampiezza."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "ppv-freq-peak-tool-balloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 490px;
}
</style>
