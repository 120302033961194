
<i18n>
{
  "en": {
    "deck": "Deck",
    "tiltmeter": "Tilt.",
    "accelerometer": "Acc.",
    "analogNode": "Node",
    "crackmeter": "Crack"
  },
  "it": {
    "deck": "Deck",
    "tiltmeter": "Inclin.",
    "accelerometer": "Acc.",
    "analogNode": "Nodo",
    "crackmeter": "Crack"
  }
}
</i18n>

<template>
  <div class="wrapper noselect">
    <div class="toggle_radio">
      <input type="radio" class="toggle_option" id="deck_toggle" value="deck" :disabled="!this.searchForType(this.sensors, 'deck')" v-model="selected" />
      <input type="radio" class="toggle_option" id="tilt_toggle" value="tiltmeter" :disabled="!this.searchForType(this.sensors, 'tiltmeter')" v-model="selected" />
      <input type="radio" class="toggle_option" id="acc_toggle" value="accelerometer" :disabled="!this.searchForType(this.sensors, 'accelerometer')" v-model="selected" />
      <input type="radio" class="toggle_option" id="crack_toggle" value="crackmeter" :disabled="!this.searchForType(this.sensors, 'crackmeter')" v-model="selected" />
      <input type="radio" class="toggle_option" id="an_toggle" value="analog-node" :disabled="!this.searchForType(this.sensors, 'analog-node')" v-model="selected" />
      <label for="deck_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'deck') }">{{ $t("deck") }}</p>
      </label>
      <label for="tilt_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'tiltmeter') }">{{ $t("tiltmeter") }}</p>
      </label>
      <label for="acc_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'accelerometer') }">{{ $t("accelerometer") }}</p>
      </label>
      <label for="crack_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'crackmeter') }">{{ $t("crackmeter") }}</p>
      </label>
      <label for="an_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'analog-node') }">{{ $t("analogNode") }}</p>
      </label>
      <div class="toggle_option_slider"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "select-type-toggle",
  props: {
    sensors: Array,
    showAll: Boolean,
    value: String,
  },
  data() {
    return {
      selected: "deck",
      types: ["deck", "tiltmeter", "accelerometer", "crackmeter", "analog-node"],
    };
  },
  methods: {
    searchForType: function (sensorsList, type) {
      let check = false;
      sensorsList.forEach((element) => {
        if (element.type === type) check = true;
      });
      return check || this.showAll;
    },
    getFirstSensorSelection: function () {
      for (let type of this.types) {
        if (this.searchForType(this.sensors, type)) {
          return type;
        }
      }
    },
    selectType: function (newType) {
      this.$emit("input", newType);
    },
  },
  watch: {
    selected(newType) {
      this.selectType(newType);
    },
    /* Se cambio il sensore selezionato da fuori adeguo il tipo */
    value(type) {
      if (type && type != this.selected) {
        this.selected = type;
      }
    },
    sensors(newSensors, oldSensors) {
      if (newSensors[0] && oldSensors[0] && newSensors[0]._id != oldSensors[0]._id) {
        this.selected = this.getFirstSensorSelection();
      }
    },
  },
  mounted() {
    this.selected = this.getFirstSensorSelection();
  },
};
</script>
<style scoped>
p {
  margin: 0px 5px;
  padding: 0px;
  position: relative;
  z-index: 2;
}

.toggle_radio {
  position: relative;
  background-color: rgb(38, 41, 55);
  padding: 0 !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 47px;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: repeat(5, 20%);
  /* width: 700px; */
  box-sizing: border-box;
  justify-items: center;
}
.toggle_radio > * {
  float: left;
}
.toggle_radio input[type="radio"] {
  display: none;
}
.toggle_radio label {
  color: rgba(255, 255, 255, 0.9);
  font-family: inherit;
  font-weight: 600;
  font-size: 0.9em;
  display: block;
  width: max-content;
  box-sizing: border-box;
  height: 20px;
  margin: 9px 22px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.toggle_option_slider {
  width: 19%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 38px;
  align-self: center;
  justify-self: baseline;
  position: absolute;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#deck_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 0%; */
  transform: translateX(1%);
}
#tilt_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 25%; */
  transform: translateX(105%);
}
#acc_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 50%; */
  transform: translateX(210%);
}
#crack_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(315%);
  /* left: 75%; */
}
#an_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(420%);
  /* left: 75%; */
}
.greyed {
  color: rgb(80, 84, 105);
}

@media all and (max-width: 1799px) and (min-width: 300px) {
  .toggle_radio {
    height: 37px;
  }
  .toggle_option_slider {
    height: 28px;
  }
}
</style>
