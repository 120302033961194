<i18n>
{
  "en": {
      "title": "Dynamic displacement",
      "subTitle": "Each event is equivalent to a stress on the structure, when the activation threshold is exceeded. The acquisition of the dynamic displacement corresponds from the previous 10 seconds to the following 20 seconds (tot. 30 seconds) to each exceeding of the activation threshold."

  },
  "it": {
      "title": "Spostamento dinamico",
      "subTitle": "Ogni evento corrisponde ad una sollecitazione della struttura, laddove venga superata la soglia di attivazione. L’acquisizione dello spostamento dinamico corrisponde dai 10 secondi precedenti ai 20 secondi successivi (tot. 30 secondi) ad ogni superamento della soglia di attivazione."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "deckBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 360px;
}
</style>
